import React, { useState, useMemo, useEffect } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import csc from 'countries-states-cities'

// @material-ui/core components
import { Fade, FormControl, FormHelperText } from '@material-ui/core'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { makeStyles, Select, MenuItem, IconButton, InputAdornment, CircularProgress } from '@material-ui/core'
import InputLabel from '@material-ui/core/InputLabel'
import Tooltip from '@material-ui/core/Tooltip'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { DropzoneArea } from 'material-ui-dropzone'

// componentsG
import CardBody from 'components/Card/CardBody'
import CardHeader from 'components/Card/CardHeader'
import Button from 'components/CustomButtons/Button.js'
import Card from 'components/Card/Card.js'
import CustomInput from 'components/CustomInput/CustomInput'
import { FieldsDetailsModal } from 'components/DataAccess/FieldsDetailsModal'
import { ConfirmAlertDialog } from 'components/Alerts/ConfirmAlert'

// @material-ui/icons
import CheckBox from '@material-ui/icons/CheckBox'
import Cancel from '@material-ui/icons/Cancel'
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined'
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined'
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

// import Close from '@material-ui/icons/Close'
import styles from 'assets/jss/material-dashboard-pro-react/views/loginViewStyle.js'
import stylesButtons from 'assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js'
import wizardStyle from '../../assets/jss/material-dashboard-pro-react/components/wizardStyle.js'
//services
import {
  blockPIISupplier,
  executeBistroValidation,
  getBistroTransaction,
  getBistroTransactionStatus,
  getTaxNamesAndCountries
} from '../../services/apiPII'
import { cancelPiiProcess } from '../../services/apiPII'
import { finalizePiiForm } from '../../services/apiPII'
import { getAnswersFromTask } from '../../services/apiPII'
import { getFormProcessSatus } from '../../services/apiPII'
import { processPIIFile } from '../../services/apiPII'
import { saveSupplierInformation, encryptAdditionalFile } from '../../services/apiPII'

// constants
import { piiValidationTypes, piiValidationUpdateOptions, piiValidationInputFormatMessages } from 'utils/Constants'
import { Spinner } from 'components/Loader/Spinner.js'
import { divideNameIntoFirstAndLast } from '../../utils/nameUtils'
import Snackbar from 'components/Snackbar/Snackbar.js'
import { AddAlert } from '@material-ui/icons'
import { AdditionalFileTypes, NameComparisonCodes } from 'utils/Constants.js'
import { BankAccountOwnershipProvider } from 'utils/Constants.js'

// utils
import { accountHolderNameComparison, supplierNameMatcher } from 'utils/functions.js'

const useStyles = makeStyles(styles)
const usesWizardStyles = makeStyles(wizardStyle)
const useStylesButtons = makeStyles(stylesButtons)
const useCustomStyles = makeStyles(theme => ({
  formControl: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  tooltipPopper: {
    zIndex: 9999
  }
}))
const useDropzoneAreaStyles = makeStyles(_theme => ({
  disabledState: {
    border: 'dashed gray',
    opacity: 0.5,
    cursor: 'default'
  }
}))

const timerReviewFileProcess = 5000
const maxRetriesFileProcess = 24

export default function PIIDataAccessForm(props) {
  const countryListOptions = useMemo(() => csc.getAllCountries(), [])
  const [formDataErrorMessage, setFormDataErrorMessage] = useState(null)
  const [formDataErrorValues, setFormDataErrorValues] = useState(false)
  const [formStatus, setFormStatus] = useState(false)
  const [showLoading, setShowLoading] = useState(true)
  const [encryptedAndSent, setEncryptedAndSent] = useState(false)
  const [validationMessage, setValidationMessage] = useState(null)
  const [warningValidationMessage, setWarningValidationMessage] = useState(null)
  const [piiMessage, setPiiMessage] = useState(
    `Thank you for submitting your information.\n\nYou can close this window, no further action is required.`
  )
  const [status, setStatus] = useState({})
  const [countriesRelish, setCountriesRelish] = useState([])
  /************************* TAX ***************************************/

  const [countryTax, setCountryTax] = useState('US')
  const [countryTaxSelectHelp, setCountryTaxSelectHelp] = useState(null)
  const [disableCountryTax, setDisableCountryTax] = useState(false)
  const [disableTinSection, setDisableTinSection] = useState(false)
  const [file, setFile] = useState(null)
  const [fileIsLoading, setFileIsLoading] = useState(false)
  const [fileMessage, setFileMessage] = useState('Please select a file')
  const [fileSelected, setFileSelected] = useState(false)
  const [hideUploadFile, setHideUploadFile] = useState(false)
  const [latestFileUsed, setLatestFileUsed] = useState(null)
  const [name, setName] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [tin, setTin] = useState(null)
  const [tinInvalidFormat, setTinInvalidFormat] = useState(false)
  const [taxInformationManuallyCompleted, setTaxInformationManuallyCompleted] = useState(false)
  const [tinAlwaysPass, setTinAlwaysPass] = useState(false)
  const [tinRetryCount, setTinRetryCount] = useState(0)
  const [uploadW8W9Form, setUploadW8W9Form] = useState(false)
  const [uploadBankDocumentationChecked, setUploadBankDocumentationChecked] = useState(false)
  const [bankDocumentationFiles, setBankDocumentationFiles] = useState([])
  const [bankDocumentationDropzoneAreaKey, setBankDocumentationDropzoneAreaKey] = useState(1)
  const [uploadTaxDocumentationChecked, setUploadTaxDocumentationChecked] = useState(false)
  const [taxDocumentationFiles, setTaxDocumentationFiles] = useState([])
  const [taxDocumentationDropzoneAreaKey, setTaxDocumentationDropzoneAreaKey] = useState(1)
  const [validationMessageTin, setValidationMessageTin] = useState(null)
  const [isProcessingTin, setIsProcessingTin] = useState(false)
  const [isProcessingBank, setIsProcessingBank] = useState(false)
  const countryTaxListOptions = useMemo(() => countryListOptions.filter(c => countriesRelish.includes(c.iso2)), [countryListOptions, countriesRelish])
  /************************* ADDITIONAL FILES ***************************************/
  const [additionalFilesMaxCount, setAdditionalFilesMaxCount] = useState(0)
  const [additionalFileIsUploading, setAdditionalFileIsUploading] = useState(false)
  const [additionalfileMessage, setAdditionalFileMessage] = useState([])
  const [additionalFileSelected, setAdditionalFileSelected] = useState(false)
  const [additionalFile, setAdditionalFile] = useState([])
  const [warningAdditionalFileMessage, setWarningAdditionalFileMessage] = useState('')
  const [additionalFileUploadResult, setAdditionalFileUploadResult] = useState('')
  /************************* BANK ***************************************/

  const [accountNumber, setAccountNumber] = useState(null)
  const [accountNumberInvalidFormat, setAccountNumberInvalidFormat] = useState(false)
  const [accountType, setAccountType] = useState('')
  const [accountHolderLegalName, setAccountHolderLegalName] = useState(null)
  const [accountHolderFirstName, setAccountHolderFirstName] = useState(null)
  const [accountHolderLastName, setAccountHolderLastName] = useState(null)
  const [bankName, setBankName] = useState(null)
  const [bankNameInvalidFormat, setBankNameInvalidFormat] = useState(false)
  const [bankRetryCount, setBankRetryCount] = useState(0)
  const [country, setCountry] = useState('United States')
  const [supplierType, setSupplierType] = useState('Individual')
  const [ibanNumber, setIbanNumber] = useState(null)
  const [ibanNumberInvalidFormat, setIbanNumberInvalidFormat] = useState(false)
  const [otherValues, setOtherValues] = useState(null)
  const [routingNumber, setRoutingNumber] = useState(null)
  const [routingNumberInvalidFormat, setRoutingNumberInvalidFormat] = useState(false)
  const [showOtherValues, setShowOtherValues] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [swiftCode, setSwiftCode] = useState(null)
  const [swiftCodeInvalidFormat, setSwiftCodeInvalidFormat] = useState(false)
  const [customBankFields, setCustomBankFields] = useState([])
  const [customBankFieldsHtml, setCustomBankFieldsHtml] = useState(null)
  const [isNotSupportedCountry, setIsNotSupportedCountry] = useState(false)
  const [taxTransactionId, setTaxTransactionId] = useState('')
  const [bankTransactionId, setBankTransactionId] = useState('')
  const [taxTransactionBypass, setTaxTransactionBypass] = useState(false)
  const [bankTransactionBypass, setBankTransactionBypass] = useState(false)
  const [bankAccountOwnershipTransactionBypass, setBankAccountOwnershipTransactionBypass] = useState(false)
  const [giactTransactionBypass, setGiactTransactionBypass] = useState(false)
  const [taxTransactionFailed, setTaxTransactionFailed] = useState(false)

  const [taxNamesList, setTaxNamesList] = useState(undefined)
  const [taxNamesForSelect, setTaxNamesForSelect] = useState([{ taxName: 'Other', taxNameVariants: ['Other tax name'] }])
  const [taxNameSelected, setTaxNameSelected] = useState('Other')
  const [errorMessage, setErrorMessage] = useState(undefined)

  const [isSupplierNameMismatch, setIsSupplierNameMismatch] = useState(false)
  const [comparisonNameMessage, setComparisonNameMessage] = useState(null)
  const [comparisonNameResult, setComparisonNameResult] = useState(undefined)

  const alwaysAllowTin = props.alwaysAllowTin || localStorage.getItem('pii.alwaysAllowTin')
  const customClasses = useCustomStyles()
  const buttonClases = useStylesButtons()
  const classes = useStyles()
  const dropzoneAreaClasses = useDropzoneAreaStyles()

  /** Confirm Save and Encrypt */
  const [openAlert, setOpenAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState('')
  const [alertDescription, setAlertDescription] = useState('')
  const [alertCloseFn, setAlertCloseFn] = useState(undefined)

  const email = props.email || localStorage.getItem('pii.email')
  const formConfigurations =
    props.formConfigurations || (localStorage.getItem('pii.formConfigurations') && JSON.parse(localStorage.getItem('pii.formConfigurations')))
  const formUpdateValidation = props.formUpdateValidation || JSON.parse(localStorage.getItem('pii.formUpdateValidation')) || []
  const isFormUpdate = props.isFormUpdate || localStorage.getItem('pii.isFormUpdate')
  const piiConf = props.piiValidation || (localStorage.getItem('piiConf') && JSON.parse(localStorage.getItem('piiConf')))
  const excludedCountries = piiConf?.parameters?.taxConfiguration?.exludedCountries || []
  const startWithW9UploadForm = props.startWithW9UploadForm || localStorage.getItem('pii.startWithW9UploadForm')
  const supplierId = props.supplierId || localStorage.getItem('pii.supplierId')
  const supplierName = props.supplierName || localStorage.getItem('pii.setSupplierName')
  const dbaName = props.dbaName || localStorage.getItem('pii.setDBAName') || undefined
  const clientId = props.clientId || localStorage.getItem('pii.clientId')
  const environmentId = props.environmentId || localStorage.getItem('pii.environmentId')
  const bistroPiiTransaction = props.bistroPiiTransaction || localStorage.getItem('pii.bistroPiiTransaction') || undefined
  const urlToken = props.urlToken || localStorage.getItem('pii.urlToken')
  const validationId = props.validationId || localStorage.getItem('pii.validationId')
  const giactConfigurations = props.giactConfigurations || JSON.parse(localStorage.getItem('giactConfigurations'))
  const bankAccountOwnershipConfigurations =
    props.bankAccountOwnershipConfigurations || JSON.parse(localStorage.getItem('bankAccountOwnershipConfigurations'))
  const additionalFileInstructions = props.additionalFileInstructions || localStorage.getItem('pii.additionalFileInstructions')
  const maxAdditionalFiles = props.maxAdditionalFiles || localStorage.getItem('pii.maxAdditionalFiles')
  const minAdditionalFiles = props.minAdditionalFiles || localStorage.getItem('pii.minAdditionalFiles') || 0
  const uploadBankDocumentationEnabled = props.uploadBankDocumentationEnabled || localStorage.getItem('pii.uploadBankDocumentationEnabled')
  const uploadTaxDocumentationEnabled = props.uploadTaxDocumentationEnabled || localStorage.getItem('pii.uploadTaxDocumentationEnabled')

  const sectionConfigurations = props.sectionConfigurations || localStorage.getItem('pii.sectionConfigurations')
  const bankSectionEnabled = props.bankSectionEnabled || localStorage.getItem('pii.bankSectionEnabled')
  const bankValidationRequired = props.bankValidationRequired || localStorage.getItem('pii.bankValidationRequired') || undefined
  const requireBankAccountHolder = props.requireBankAccountHolder || localStorage.getItem('pii.requireBankAccountHolder') || false
  const bankAccountOwnershipValidationEnabled =
    props.bankAccountOwnershipValidationEnabled || localStorage.getItem('pii.bankAccountOwnershipValidationEnabled') || false
  const bankAccountOwnershipValidationProvider =
    props.bankAccountOwnershipValidationProvider ||
    localStorage.getItem('pii.bankAccountOwnershipValidationProvider') ||
    BankAccountOwnershipProvider.DATA_ASSURE

  const taxSectionEnabled = props.taxSectionEnabled || localStorage.getItem('pii.taxSectionEnabled')
  const taxValidationRequired = props.taxValidationRequired || localStorage.getItem('pii.taxValidationRequired') || undefined
  const attachmentSectionEnabled = props.attachmentSectionEnabled || localStorage.getItem('pii.attachmentSectionEnabled')

  const mandatoryFieldsConfiguration = props.mandatoryFieldsConfiguration || localStorage.getItem('pii.mandatoryFieldsConfiguration')
  const accountTypeField = props.accountTypeField || localStorage.getItem('pii.accountTypeField') || undefined
  const accountNumberField = props.accountNumberField || localStorage.getItem('pii.accountNumberField') || undefined
  const ibanNumberField = props.ibanNumberField || localStorage.getItem('pii.ibanNumberField') || undefined
  const routingNumberField = props.routingNumberField || localStorage.getItem('pii.routingNumberField') || undefined
  const swiftCodeField = props.swiftCodeField || localStorage.getItem('pii.swiftCodeField') || undefined

  const includeBankAccountType = props.includeBankAccountType || localStorage.getItem('pii.includeBankAccountType') || undefined
  const maxAttemptsTax = props.maxAttemptsTax || localStorage.getItem('pii.maxAttemptsTax') || 3
  const maxAttemptsBank = props.maxAttemptsBank || localStorage.getItem('pii.maxAttemptsBank') || 5
  const allowOtherNonValidatedTaxID = props.allowOtherNonValidatedTaxID || localStorage.getItem('pii.allowOtherNonValidatedTaxID') === 'true'

  const performNameComparison = props.performNameComparison || localStorage.getItem('pii.performNameComparison')
  const nameComparisonPassThreshold = props.nameComparisonPassThreshold || localStorage.getItem('pii.nameComparisonPassThreshold')
  const nameComparisonWarningThreshold = props.nameComparisonWarningThreshold || localStorage.getItem('pii.nameComparisonWarningThreshold')

  const wizardClasses = usesWizardStyles()

  const [validationStatus, setValidationStatus] = useState({
    [piiValidationTypes.bank]: false,
    [piiValidationTypes.bankAccountOwnership]: false,
    [piiValidationTypes.giact]: false,
    [piiValidationTypes.tin]: false,
    [piiValidationTypes.file]: false,
    [piiValidationTypes.attachment]: false
  })

  const queryClient = useQueryClient()

  const processingStatus = ['PROCESSING', 'PENDING']
  const passedResults = ['BYPASS', 'PASSED', 'PASSED WITH CAUTIONS']

  useQuery(
    ['getTaxTransaction', taxTransactionId],
    () => getBistroTransactionStatus({ appId: environmentId, transactionId: taxTransactionId, urlToken: urlToken }),
    {
      enabled: !!taxTransactionId,
      refetchInterval: 10000,
      refetchIntervalInBackground: false,
      onSuccess: async data => {
        if (data) {
          if (!processingStatus.includes(data.status)) {
            if (data.status === 'COMPLETED') {
              if (passedResults.includes(data.result)) {
                setTaxTransactionBypass(data.result === 'BYPASS')
                updateValidationStatus(piiValidationTypes.tin, true)
                setTaxTransactionFailed(false)
              } else {
                const message = data.validations[0].output.messages.errors.join('\n\n')
                setValidationMessageTin(message ? `Error Messages:\n\n${message}` : 'Error tax data')
                updateValidationStatus(piiValidationTypes.tin, false)
                setTinRetryCount(tinRetryCount + 1)
                setTaxTransactionFailed(true)
              }
            } else {
              //Error, Error Notified, Error Not Notified
              const transaction = await getBistroTransaction({ appId: environmentId, transactionId: taxTransactionId, urlToken: urlToken })
              const stepFunctionExecutionId = transaction.stepFunctionUrl
                ? `executionId: ${transaction.stepFunctionUrl
                    .split('/')
                    .pop()
                    .split(':')
                    .pop()}`
                : `transactionId: ${taxTransactionId}`
              setValidationMessageTin(
                `Errors:\nAn error has occurred. Report it to Relish Support with the following code: {${stepFunctionExecutionId}}`
              )
              updateValidationStatus(piiValidationTypes.tin, false)
              setTaxTransactionFailed(true)
            }

            setTaxTransactionId(undefined)
            setIsProcessingTin(false)
            return
          }
        }
      },
      onError: error => {
        console.log(error)
      }
    }
  )

  useQuery(
    ['getBankTransaction', bankTransactionId],
    () => getBistroTransactionStatus({ appId: environmentId, transactionId: bankTransactionId, urlToken: urlToken }),
    {
      enabled: !!bankTransactionId,
      refetchInterval: 10000,
      refetchIntervalInBackground: false,
      onSuccess: async data => {
        if (data) {
          if (!processingStatus.includes(data.status)) {
            if (data.status === 'COMPLETED') {
              if (
                passedResults.includes(data.result) ||
                (['dev', 'stage'].includes(process.env.REACT_APP_ENVIRONMENT) &&
                  bankAccountOwnershipValidationEnabled === 'true' &&
                  !!data.validationSummary.find(vs => ['bankAccountOwnership', 'giact'].includes(vs.type) && passedResults.includes(vs.result)))
              ) {
                if (data.result === 'PASSED WITH CAUTIONS') {
                  const message = data.validations.flatMap(v => v.output.messages.warnings).join('\n\n')
                  setWarningValidationMessage(message ? `Warning Messages:\n${message}` : 'Warning banking data')
                  setBankRetryCount(bankRetryCount + 1)
                }
                setBankTransactionBypass(data.validationSummary.find(vs => vs.type === 'bank')?.result === 'BYPASS')
                setBankAccountOwnershipTransactionBypass(data.validationSummary.find(vs => vs.type === 'bankAccountOwnership')?.result === 'BYPASS')
                setGiactTransactionBypass(data.validationSummary.find(vs => vs.type === 'giact')?.result === 'BYPASS')
                updateValidationStatus(piiValidationTypes.bank, true)
                updateValidationStatus(
                  piiValidationTypes.bankAccountOwnership,
                  !!data.validationSummary.find(vs => vs.type === 'bankAccountOwnership')
                )
                updateValidationStatus(piiValidationTypes.giact, !!data.validationSummary.find(vs => vs.type === 'giact'))
              } else {
                const message = data.validations.flatMap(v => v.output.messages.errors).join('\n\n')
                setValidationMessage(message ? `Error Messages:\n\n${message}` : 'Error banking data')
                updateValidationStatus(piiValidationTypes.bank, false)
                updateValidationStatus(piiValidationTypes.bankAccountOwnership, false)
                updateValidationStatus(piiValidationTypes.giact, false)
                setBankRetryCount(bankRetryCount + 1)
              }
            } else {
              const transaction = await getBistroTransaction({ appId: environmentId, transactionId: bankTransactionId, urlToken: urlToken })
              const stepFunctionExecutionId = transaction.stepFunctionUrl
                ? `executionId: ${transaction.stepFunctionUrl
                    .split('/')
                    .pop()
                    .split(':')
                    .pop()}`
                : `transactionId: ${bankTransactionId}`
              setValidationMessageTin(
                `Errors:\nAn error has occurred. Report it to Relish Support with the following code: {${stepFunctionExecutionId}}`
              )
              updateValidationStatus(piiValidationTypes.bank, false)
              updateValidationStatus(piiValidationTypes.bankAccountOwnership, false)
              updateValidationStatus(piiValidationTypes.giact, false)
            }

            setBankTransactionId(undefined)
            setIsProcessingBank(false)
            return
          }
        }
      },
      onError: error => {
        console.log(error)
      }
    }
  )

  useQuery('getTaxNames', () => getTaxNamesAndCountries({ urlToken: urlToken }), {
    enabled: false,
    staleTime: 3600000 * 24,
    retry: 3,
    retryDelay: 1000,
    onSuccess: data => {
      setCountriesRelish(data.countries)
      setTaxNamesList(data.taxNames)
      setShowLoading(false)
    }
  })

  const { mutate: mutateBistroValidation } = useMutation(executeBistroValidation, {
    retry: (failureCount, error) => {
      console.log('On Retry BistroValidation')
      return failureCount <= 2 && error.status === 504
    },
    retryDelay: 1000,
    onSuccess: async data => {
      if (data.transactionId) {
        switch (data.type) {
          case piiValidationTypes.bank:
            setBankTransactionId(data.transactionId)
            setIsProcessingBank(true)
            setShowLoading(false)
            break
          case piiValidationTypes.tin:
            setTaxTransactionId(data.transactionId)
            setIsProcessingTin(true)
            setShowLoading(false)
            break
          default:
            setBankTransactionId(undefined)
            setTaxTransactionId(undefined)
            setIsProcessingBank(false)
            setIsProcessingTin(false)
            setShowLoading(false)
            break
        }
      } else {
        console.log('Error, transactionId not defined')
        setShowLoading(false)
      }
    },
    onError: error => {
      setValidationMessageTin('An error has occurred, wait for 30 seconds and try again')
      setShowLoading(false)
    },
    onSettled: () => {
      queryClient.invalidateQueries('create')
    }
  })

  const setInitialStatus = React.useCallback(() => {
    const customFieldBankStatus = {}
    // eslint-disable-next-line
    for (const key in formConfigurations?.customFields?.bankFields) {
      const bankFieldConf = formConfigurations?.customFields?.bankFields[key]
      customFieldBankStatus[bankFieldConf.apiResponseId] = null
    }
    setStatus({
      name: null,
      firstName: null,
      lastName: null,
      tin: null,
      accountNumber: null,
      accountHolderLegalName: null,
      accountHolderFirstName: null,
      accountHolderLastName: null,
      ibanNumber: null,
      bankName: null,
      swiftCode: null,
      routingNumber: null,
      validateData: null,
      file: null,
      ...customFieldBankStatus
    })
  }, [formConfigurations])

  const addCustomFields = React.useCallback(
    fieldSection => {
      if (fieldSection === 'bankFields') {
        // eslint-disable-next-line
        for (const key in formConfigurations?.customFields?.[fieldSection]) {
          const bankFieldConf = formConfigurations?.customFields?.[fieldSection][key]
          setCustomBankFields(old => [
            ...old,
            {
              id: bankFieldConf.apiResponseId,
              required: bankFieldConf.required,
              label: bankFieldConf.label,
              value: null
            }
          ])
        }
      }
    },
    [formConfigurations]
  )

  useEffect(() => {
    setInitialStatus()
    //Set Custom Fields
    addCustomFields('bankFields')
    //Tin Section
    setDisableTinSection(false)
    //Upload File Switch
    setUploadW8W9Form(startWithW9UploadForm === 'true')
    // Set Tin always pass - validation will not take place and set true
    setTinAlwaysPass(alwaysAllowTin === 'true' ? true : false)
    // Set status for additional files
    let newValidationStatus = { ...validationStatus }
    newValidationStatus[piiValidationTypes.attachment] = minAdditionalFiles > 0 ? false : true
    setValidationStatus(newValidationStatus)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setShowLoading(true)
    queryClient.refetchQueries('getTaxNames')
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let validated = true
    let validationTypes = []

    if (isFormUpdate && isFormUpdate === 'true') {
      if (formUpdateValidation.includes(piiValidationUpdateOptions.all)) {
        if (!disableTinSection || (sectionConfigurations && taxSectionEnabled === 'true')) {
          validationTypes.push(piiValidationTypes.tin)
        }
        if (sectionConfigurations && bankSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.bank)
        }
        if (sectionConfigurations && attachmentSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.attachment)
        }
      }

      if (formUpdateValidation.includes(piiValidationUpdateOptions.tin)) {
        if (!disableTinSection || (sectionConfigurations && taxSectionEnabled === 'true')) {
          validationTypes.push(piiValidationTypes.tin)
        }
      }

      if (formUpdateValidation.includes(piiValidationUpdateOptions.bank)) {
        if (sectionConfigurations && bankSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.bank)
        }
      }

      if (formUpdateValidation.includes(piiValidationUpdateOptions.attachment)) {
        if (sectionConfigurations && attachmentSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.attachment)
        }
      }
    } else {
      if (sectionConfigurations && taxSectionEnabled === 'true') {
        validationTypes.push(piiValidationTypes.tin)
      }
      if (sectionConfigurations && bankSectionEnabled === 'true') {
        validationTypes.push(piiValidationTypes.bank)
      }
      if (sectionConfigurations && attachmentSectionEnabled === 'true') {
        validationTypes.push(piiValidationTypes.attachment)
      }
    }
    console.log('validation types result', { context: validationTypes, validationStatus })
    for (let index = 0; index < validationTypes.length; index++) {
      const validationType = validationTypes[index]

      switch (validationType) {
        case piiValidationTypes.tin:
          if (taxValidationRequired === 'true') {
            validated = validated && validationStatus[validationType]
          }
          break
        case piiValidationTypes.bank:
          if (bankValidationRequired === 'true') {
            validated = validated && validationStatus[validationType]
          }
          break
        default:
          validated = validated && validationStatus[validationType]
          break
      }
    }
    console.log('validated form final result 0 ', { context: validated })

    setFormStatus(validated)
  }, [
    validationStatus,
    disableTinSection,
    formUpdateValidation,
    sectionConfigurations,
    attachmentSectionEnabled,
    bankSectionEnabled,
    bankValidationRequired,
    taxSectionEnabled,
    taxValidationRequired,
    startWithW9UploadForm,
    isFormUpdate
  ])

  useEffect(() => {
    if (sectionConfigurations && taxSectionEnabled === 'true') {
      if (countryTax) {
        console.log('Change CountryTax: ', countryTax)
        //Check if country is on Not Supported Countries List
        if (excludedCountries.includes(countryTax)) {
          setIsNotSupportedCountry(true)
        } else {
          setIsNotSupportedCountry(false)
        }

        setDisableTinSection(false)
      }
    }
  }, [countryTax, excludedCountries, sectionConfigurations, taxSectionEnabled])

  useEffect(() => {
    if (countryTax && taxNamesList && supplierType) {
      const country = taxNamesList[countryTax]
      let items = []

      if (country) {
        items = supplierType === 'Organization' ? country?.organization || [] : country?.individual || []
      }
      items = [...items, { taxName: 'Other', taxNameVariants: ['Other tax name'] }]
      setTaxNamesForSelect(items)
      setTaxNameSelected(items[0].taxName)
    }
  }, [countryTax, supplierType, taxNamesList])

  useEffect(() => {
    setValidationMessageTin('')
    if (!allowOtherNonValidatedTaxID && taxNameSelected === 'Other' && !showLoading) {
      setValidationMessageTin('Your client has disallowed the use of "Other Tax ID type", please select one of the other options')
    }
  }, [taxNameSelected, allowOtherNonValidatedTaxID, showLoading])

  useEffect(() => {
    if (customBankFields.length > 0) {
      setCustomBankFieldsHtml(
        customBankFields.map(field => (
          <GridItem xs={12} key={field.id}>
            <CustomInput
              labelText={field.label + `${field.required ? '*' : ''}`}
              labelProps={{ style: { width: '100%', float: 'left' } }}
              id={field.apiResponseId}
              formControlProps={{ fullWidth: true }}
              required={field.required}
              inputProps={{
                type: 'text',
                value: (() => field?.value || '')(),
                onChange: event => {
                  const newCustomBankFields = customBankFields.map(f => {
                    let temp = Object.assign({}, f)
                    temp.value = temp.id === field.id ? event.target.value : temp.value
                    return temp
                  })
                  setCustomBankFields(newCustomBankFields)
                },
                disabled: (() => {
                  let isDisable = false
                  if (validationStatus[[piiValidationTypes.bank]] || isProcessingBank || isSupplierNameMismatch) {
                    isDisable = true
                  }
                  return isDisable
                })(),
                endAdornment:
                  status[field.apiResponseId] === true ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status[field.apiResponseId] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
              }}
            />
          </GridItem>
        ))
      )
    } else {
      setCustomBankFieldsHtml(null)
    }
  }, [customBankFields, status, validationStatus, isProcessingBank])

  useEffect(() => {
    if (accountNumber || ibanNumber) {
      setStatus(old => ({
        ...old,
        accountNumber: accountNumber ? true : null,
        ibanNumber: ibanNumber ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        accountNumber: accountNumber !== null ? false : null,
        ibanNumber: ibanNumber !== null ? false : null
      }))
    }
    if (routingNumber || swiftCode) {
      setStatus(old => ({
        ...old,
        routingNumber: routingNumber ? true : null,
        swiftCode: swiftCode ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        routingNumber: routingNumber !== null ? false : null,
        swiftCode: swiftCode !== null ? false : null
      }))
    }
    if (bankName) {
      setStatus(old => ({
        ...old,
        bankName: bankName ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        bankName: bankName !== null ? false : null
      }))
    }
    if (name) {
      setStatus(old => ({
        ...old,
        name: name ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        name: name !== null ? false : null
      }))
    }

    if (firstName) {
      setStatus(old => ({
        ...old,
        firstName: firstName ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        firstName: firstName !== null ? false : null
      }))
    }

    if (lastName) {
      setStatus(old => ({
        ...old,
        lastName: lastName ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        lastName: lastName !== null ? false : null
      }))
    }

    if (accountHolderLegalName) {
      setStatus(old => ({
        ...old,
        accountHolderLegalName: accountHolderLegalName ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        accountHolderLegalName: accountHolderLegalName !== null ? false : null
      }))
    }

    if (accountHolderFirstName) {
      setStatus(old => ({
        ...old,
        accountHolderFirstName: accountHolderFirstName ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        accountHolderFirstName: accountHolderFirstName !== null ? false : null
      }))
    }

    if (accountHolderLastName) {
      setStatus(old => ({
        ...old,
        accountHolderLastName: accountHolderLastName ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        accountHolderLastName: accountHolderLastName !== null ? false : null
      }))
    }

    if (tin) {
      setStatus(old => ({
        ...old,
        tin: tin ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        tin: tin !== null ? false : null
      }))
    }
    if (file) {
      setStatus(old => ({
        ...old,
        file: file ? true : null
      }))
    } else {
      setStatus(old => ({
        ...old,
        file: file !== null ? false : null
      }))
    }
  }, [
    accountNumber,
    ibanNumber,
    routingNumber,
    swiftCode,
    bankName,
    customBankFields,
    accountHolderLegalName,
    accountHolderFirstName,
    accountHolderLegalName,
    name,
    firstName,
    lastName,
    tin,
    file
  ])

  const { isLoading: isLoadingCountryKi } = useQuery(
    ['CountryKi', 'CountryKi'],
    () => {
      setDisableCountryTax(false)
      return getAnswersFromTask(clientId, environmentId, validationId)
    },
    {
      enabled: Boolean(piiConf?.parameters?.taxConfiguration?.readCountry === 'ki'),
      refetchOnWindowFocus: false,
      onSuccess: answers => {
        const kiExtendedAddress = answers?.mapping?.extendedAddress
        const countryAnswer = answers?.answers?.find(a => a.searchValue === kiExtendedAddress)
        let newCountryTax = 'US'
        if (countryAnswer) {
          const countryKi = countryAnswer.values[0]?.country
          if (countryTaxListOptions.map(c => c.iso2).includes(countryKi)) {
            newCountryTax = countryKi
            //setDisableCountryTax(true)
            setCountryTaxSelectHelp(`Country retrieved from KI`)
          } else {
            setCountryTaxSelectHelp(`Country from KI (${countryAnswer.values[0]?.countryName}) not admitted, please select one`)
          }
        } else {
          setCountryTaxSelectHelp(`Couldn't find the country from KI, please select one`)
        }
        setCountryTax(newCountryTax)
      },
      onError: () => {
        setCountryTax('US')
        setCountryTaxSelectHelp(`Error retrieving the country from KI, please select one`)
      }
    }
  )

  function nameComparisonFn(value, origin) {
    if (performNameComparison !== undefined && performNameComparison === 'true' && bistroPiiTransaction !== undefined) {
      let piiFormName = ''
      switch (origin) {
        case 'NAME':
          piiFormName = value
          break
        case 'FIRST_NAME':
          piiFormName = value + ' ' + lastName
          break
        case 'LAST_NAME':
          piiFormName = firstName + ' ' + value
          break
      }

      if (piiFormName) {
        const legalNameComparison = supplierNameMatcher(piiFormName, supplierName, nameComparisonWarningThreshold, nameComparisonPassThreshold, false)
        setComparisonNameResult(legalNameComparison)

        if (NameComparisonCodes.LEGAL_NAME.NOT_MATCH === legalNameComparison.code) {
          if (dbaName) {
            const dbaNameComparsion = supplierNameMatcher(piiFormName, dbaName, nameComparisonWarningThreshold, nameComparisonPassThreshold, true)
            setComparisonNameResult(dbaNameComparsion)

            if (NameComparisonCodes.DBA_NAME.NOT_MATCH === dbaNameComparsion.code) {
              setComparisonNameMessage(
                `The supplier’s name provided does not match with the names registered in the system. You can either update the entered data or cancel the PII registration process to update your information with the contractor.`
              )
              setIsSupplierNameMismatch(true)
            } else {
              setComparisonNameMessage(null)
              setIsSupplierNameMismatch(false)
            }
          } else {
            setComparisonNameMessage(
              `The supplier’s name provided does not match with the name registered in the system. You can either update the entered data or cancel the PII registration process to update your information with the contractor.`
            )
            setIsSupplierNameMismatch(true)
          }
        } else {
          setComparisonNameMessage(null)
          setIsSupplierNameMismatch(false)
        }
      } else {
        setComparisonNameMessage(null)
        setIsSupplierNameMismatch(false)
        setComparisonNameResult(undefined)
      }
    }
  }
  function validateCustomBankFields() {
    let customFieldsValidated = false
    let index = 0
    let message = ''
    if (customBankFields.length === 0) return true
    //any required custom Field with no Valuey
    let invalidAction = customBankFields.some(obj => {
      return obj.required === true && !obj.value
    })
    if (invalidAction) {
      //Set Message
      for (index = 0; index < customBankFields.length; index++) {
        let field = customBankFields[index]
        if (field) {
          message += field.label + ` required.\n `
        }
      }
      if (message.length > 0) {
        setValidationMessage(message)
      }
    } else customFieldsValidated = true
    return customFieldsValidated
  }
  function clearCustomBankFields() {
    console.log('clear customs', { context: customBankFields })
    if (customBankFields.length > 0) {
      const clearedCustomFields = customBankFields.map(f => {
        let temp = Object.assign({}, f)
        temp.value = ''
        return temp
      })
      console.log('clear customs result ', { context: clearedCustomFields })
      setCustomBankFields(clearedCustomFields)
    }
  }
  function validateInputFields(validationName) {
    let allowValidate = false
    console.log('validate inputs', {
      context: validationName,
      tax: { tinInvalidFormat },
      bank: {
        accountNumberInvalidFormat,
        bankNameInvalidFormat,
        routingNumberInvalidFormat,
        swiftCodeInvalidFormat,
        ibanNumberInvalidFormat
      }
    })
    switch (validationName) {
      case piiValidationTypes.tin:
        if (!tinInvalidFormat && tin && tin.length) {
          if (
            bankAccountOwnershipValidationEnabled === 'true' &&
            ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE && bankAccountOwnershipConfigurations?.enabled) ||
              (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled))
          ) {
            allowValidate =
              (supplierType === 'Individual' && firstName && firstName.length && lastName && lastName.length) ||
              (supplierType === 'Organization' && name && name.length)
          } else {
            allowValidate = name && name.length
          }
        }
        break
      case piiValidationTypes.bank:
        if (mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true') {
          const checkAccountType = accountTypeField && accountTypeField === 'true'
          const checkAccountNumber = accountNumberField && accountNumberField === 'true'
          const checkIbanNumber = ibanNumberField && ibanNumberField === 'true'
          const checkRoutingNumber = routingNumberField && routingNumberField === 'true'
          const checkSwiftCode = swiftCodeField && swiftCodeField === 'true'

          if (!bankNameInvalidFormat && bankName && bankName.length > 0) {
            if (
              !accountNumberInvalidFormat &&
              (accountNumber || !checkAccountNumber) &&
              !ibanNumberInvalidFormat &&
              (ibanNumber || !checkIbanNumber) &&
              !routingNumberInvalidFormat &&
              (routingNumber || !checkRoutingNumber) &&
              !swiftCodeInvalidFormat &&
              (swiftCode || !checkSwiftCode) &&
              ((includeBankAccountType === 'true' && accountType) || !checkAccountType)
            ) {
              allowValidate = true
            }
          }
        } else {
          if (
            ((!accountNumberInvalidFormat && accountNumber && accountNumber.length > 0) ||
              (!ibanNumberInvalidFormat && ibanNumber && ibanNumber.length > 0)) &&
            !bankNameInvalidFormat &&
            bankName &&
            bankName.length > 0 &&
            ((!routingNumberInvalidFormat && routingNumber && routingNumber.length > 0) ||
              (!swiftCodeInvalidFormat && swiftCode && swiftCode.length > 0))
          )
            allowValidate = true
        }

        if (requireBankAccountHolder === 'true' && bankAccountOwnershipValidationEnabled === 'true') {
          allowValidate =
            allowValidate &&
            ((supplierType === 'Individual' &&
              accountHolderFirstName &&
              accountHolderFirstName.length &&
              accountHolderLastName &&
              accountHolderLastName.length) ||
              (supplierType === 'Organization' && accountHolderLegalName && accountHolderLegalName.length))
        }
        break
      default:
        break
    }
    console.log('result validate fields', { context: allowValidate })
    return allowValidate
  }
  async function confirmCancellationPiiForm() {
    setAlertCloseFn(confirm => async confirm => {
      setOpenAlert(false)
      if (confirm) {
        await proceedCancellationPiiForm()
      }
    })
    setAlertTitle('Cancel the PII Registration Form?')
    setAlertDescription(
      'You are about to cancel the PII registration form. This is because the information saved in the system does not match the information entered in the PII form, so it is necessary for the contractor to reset your process to update your information.'
    )
    setOpenAlert(true)
  }
  async function proceedCancellationPiiForm() {
    setShowLoading(true)
    const response = await cancelPiiProcess(clientId, environmentId, validationId, [comparisonNameResult].filter(Boolean), urlToken)

    if (response.success) {
      console.log('PII Form Registration was cancelled Successfully')
      localStorage.removeItem('pii.clientId')
      localStorage.removeItem('pii.environmentId')
      localStorage.removeItem('pii.validationId')
      localStorage.removeItem('giactConfigurations')
      localStorage.removeItem('pii.email')
      localStorage.removeItem('pii.bistroPiiTransaction')
      localStorage.removeItem('pii.supplierId')
      localStorage.removeItem('pii.setSupplierName')
      localStorage.removeItem('pii.setDBAName')
      localStorage.removeItem('pii.isFormUpdate')
      localStorage.removeItem('pii.urlToken')
      localStorage.removeItem('pii.formUpdateValidation')
      localStorage.removeItem('pii.formConfigurations')
      localStorage.removeItem('pii.startWithW9UploadForm')
      localStorage.removeItem('pii.piiConf')
      localStorage.removeItem('pii.additionalFileInstructions')
      localStorage.removeItem('pii.maxAdditionalFiles')
      localStorage.removeItem('pii.minAdditionalFiles')
      localStorage.removeItem('pii.sectionConfigurations')
      localStorage.removeItem('pii.bankSectionEnabled')
      localStorage.removeItem('pii.bankValidationRequired')
      localStorage.removeItem('pii.giactValidationEnabled')
      localStorage.removeItem('pii.taxSectionEnabled')
      localStorage.removeItem('pii.taxValidationRequired')
      localStorage.removeItem('pii.attachmentSectionEnabled')
      localStorage.removeItem('pii.mandatoryFieldsConfiguration')
      localStorage.removeItem('pii.accountTypeField')
      localStorage.removeItem('pii.accountNumberField')
      localStorage.removeItem('pii.ibanNumberField')
      localStorage.removeItem('pii.routingNumberField')
      localStorage.removeItem('pii.swiftCodeField')
      localStorage.removeItem('pii.allowOtherNonValidatedTaxID')
      localStorage.removeItem('pii.maxAttemptsTax')
      localStorage.removeItem('pii.maxAttemptsBank')
      localStorage.removeItem('pii.performNameComparison')
      localStorage.removeItem('pii.nameComparisonPassThreshold')
      localStorage.removeItem('pii.nameComparisonWarningThreshold')
      setCustomBankFields([])
      setLatestFileUsed(null)

      setPiiMessage(`${response.data.message}\n\nYou can close this window; your registration process will be resumed by the contractor.`)
      setEncryptedAndSent(true)
      setShowLoading(false)
    } else {
      setErrorMessage(`${response.message}`)
      setShowLoading(false)
    }
  }

  function resetFields(validation) {
    setValidationMessage(null)
    setValidationMessageTin(null)
    setWarningValidationMessage(null)
    setFormDataErrorMessage(null)

    //clear tax section
    setValidationStatus({
      [piiValidationTypes.bank]: false,
      [piiValidationTypes.bankAccountOwnership]: false,
      [piiValidationTypes.giact]: false,
      [piiValidationTypes.tin]: false,
      [piiValidationTypes.file]: false,
      [piiValidationTypes.attachment]: validationStatus[piiValidationTypes.attachment]
    })
    setFileSelected(false)
    setBankDocumentationFiles([])
    setBankDocumentationDropzoneAreaKey(bankDocumentationDropzoneAreaKey + 1)
    setUploadBankDocumentationChecked(false)
    setTaxDocumentationFiles([])
    setTaxDocumentationDropzoneAreaKey(bankDocumentationDropzoneAreaKey + 1)
    setUploadTaxDocumentationChecked(false)
    setUploadW8W9Form(startWithW9UploadForm === 'true' ? true : false)
    setShowOtherValues(false)
    setOtherValues(null)
    setShowLoading(false)
    setFileIsLoading(false)
    setHideUploadFile(false)
    setFile(null)
    setFileMessage('Please select a file')
    setSupplierType('Individual')
    setName('')
    setFirstName('')
    setLastName('')
    setTin('')
    //clear banks section
    setAccountType('')
    setAccountNumber('')
    setAccountHolderLegalName('')
    setAccountHolderFirstName('')
    setAccountHolderLastName('')
    setBankName('')
    setRoutingNumber('')
    setSwiftCode('')
    setIbanNumber('')
    setFormDataErrorValues(false)
    setFormDataErrorMessage(null)
    //form status
    setFormStatus(false)
    //status inputs
    setStatus({
      name: false,
      firstName: false,
      lastName: false,
      tin: false,
      accountNumber: false,
      accountHolderLegalName: false,
      accountHolderFirstName: false,
      accountHolderLastName: false,
      ibanNumber: false,
      bankName: false,
      swiftCode: false,
      routingNumber: false,
      validateData: false,
      file: false
    })
    setLatestFileUsed(null)
    setTaxInformationManuallyCompleted(false)
    setTinInvalidFormat(false)
    setCountryTax('US')
    setAccountNumberInvalidFormat(false)
    setBankNameInvalidFormat(false)
    setIbanNumberInvalidFormat(false)
    setRoutingNumberInvalidFormat(false)
    setSwiftCodeInvalidFormat(false)
    setCountry('United States')
    clearCustomBankFields()
    //Transaction Bistro
    setIsProcessingBank(false)
    setIsProcessingTin(false)
    setTaxTransactionId(undefined)
    setBankTransactionId(undefined)
    setTaxTransactionFailed(false)
    //Name Comparison
    setIsSupplierNameMismatch(false)
    setComparisonNameMessage(null)
    setComparisonNameResult(undefined)
  }
  function setFileUploaded(files) {
    setFile([...files])
    if (files && files[0]) {
      setFileSelected(true)
      setFileMessage(files[0].name)
      setLatestFileUsed(files[0].name)
    } else {
      setFileSelected(false)
    }
  }
  function showTinFileOtherValues(e) {
    e.preventDefault()
    setShowOtherValues(true)
  }
  function thanksPage() {
    return (
      <div className={classes.container} style={{ paddingTop: '20%', paddingBottom: '15%' }}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <Card className={classes.card}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary" style={{ paddingTop: '0%' }}>
                <h3 className={classes.cardTitle}>Sensitive Data Entry and Verification Form</h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="position:absolute" justifyContent="center">
                  <GridItem xs={12}>
                    <h4 style={{ textAlign: 'center' }}>
                      <strong>{piiMessage}</strong>
                    </h4>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    )
  }

  async function confirmEncryptAndSendPiiForm() {
    setAlertCloseFn(confirm => async confirm => {
      setOpenAlert(false)
      if (confirm) {
        await onSendData()
      }
    })
    setAlertTitle('Encrypt and Submit Form Confirmation')
    setAlertDescription(
      'Please ensure all data is correct before encryption and transmission. You will not be able to open this form again. Are you sure you want to proceed?'
    )
    setOpenAlert(true)
  }

  async function onSendData() {
    let response
    // let errorMessage
    if (formStatus) {
      setShowLoading(true)
      setValidationMessage(null)
      setValidationMessageTin(null)
      setFormDataErrorValues(false)
      setFormDataErrorMessage(null)

      for (const taxDocumentationFile of taxDocumentationFiles) {
        response = await encryptAdditionalFile(
          clientId,
          environmentId,
          validationId,
          taxDocumentationFile,
          urlToken,
          AdditionalFileTypes.BANK_DOCUMENTATION
        )
        if (!response.success) {
          setErrorMessage(`Error uploading tax documentation: ${response.message}`)
          setShowLoading(false)
          return
        }
      }

      // eslint-disable-next-line
      for (const bankDocumentationFile of bankDocumentationFiles) {
        response = await encryptAdditionalFile(
          clientId,
          environmentId,
          validationId,
          bankDocumentationFile,
          urlToken,
          AdditionalFileTypes.TAX_DOCUMENTATION
        )
        if (!response.success) {
          setErrorMessage(`Error uploading tax documentation: ${response.message}`)
          setShowLoading(false)
          return
        }
      }

      // Execute account holder name comparison
      let accountHolderNameComparisonResult = undefined
      let accountHolderNameMismatch = undefined

      if (performNameComparison !== undefined && performNameComparison === 'true' && bistroPiiTransaction !== undefined) {
        if (requireBankAccountHolder === 'true') {
          const piiFormName = accountHolderLegalName || [accountHolderFirstName, accountHolderLastName].join(' ')
          accountHolderNameComparisonResult = accountHolderNameComparison(
            piiFormName,
            supplierName,
            dbaName,
            nameComparisonWarningThreshold,
            nameComparisonPassThreshold
          )

          accountHolderNameMismatch = ![NameComparisonCodes.ACCOUNT_HOLDER_NAME.MATCH].includes(accountHolderNameComparisonResult?.code)
        }
      }

      //send data to encrypt
      response = await saveSupplierData(clientId, environmentId, validationId, accountHolderNameMismatch)
      if (response.success) {
        response = await finalizePiiForm(
          clientId,
          environmentId,
          validationId,
          'Completed',
          'Confirmed and sent',
          email,
          latestFileUsed,
          urlToken,
          [comparisonNameResult, accountHolderNameComparisonResult].filter(Boolean)
        )
        console.log('finalize response', { context: response })
        if (response.success) {
          console.log('finalize response2')
          //local storage
          localStorage.removeItem('pii.clientId')
          localStorage.removeItem('pii.environmentId')
          localStorage.removeItem('pii.validationId')
          localStorage.removeItem('giactConfigurations')
          localStorage.removeItem('bankAccountOwnershipConfigurations')
          localStorage.removeItem('pii.email')
          localStorage.removeItem('pii.bistroPiiTransaction')
          localStorage.removeItem('pii.supplierId')
          localStorage.removeItem('pii.setSupplierName')
          localStorage.removeItem('pii.setDBAName')
          localStorage.removeItem('pii.isFormUpdate')
          localStorage.removeItem('pii.urlToken')
          localStorage.removeItem('pii.formUpdateValidation')
          localStorage.removeItem('pii.formConfigurations')
          localStorage.removeItem('pii.startWithW9UploadForm')
          localStorage.removeItem('pii.piiConf')
          localStorage.removeItem('pii.additionalFileInstructions')
          localStorage.removeItem('pii.maxAdditionalFiles')
          localStorage.removeItem('pii.minAdditionalFiles')
          localStorage.removeItem('pii.sectionConfigurations')
          localStorage.removeItem('pii.bankSectionEnabled')
          localStorage.removeItem('pii.bankValidationRequired')
          localStorage.removeItem('pii.requireBankAccountHolder')
          localStorage.removeItem('pii.bankAccountOwnershipValidationEnabled')
          localStorage.removeItem('pii.bankAccountOwnershipValidationProvider')
          localStorage.removeItem('pii.taxSectionEnabled')
          localStorage.removeItem('pii.taxValidationRequired')
          localStorage.removeItem('pii.attachmentSectionEnabled')
          localStorage.removeItem('pii.mandatoryFieldsConfiguration')
          localStorage.removeItem('pii.accountTypeField')
          localStorage.removeItem('pii.accountNumberField')
          localStorage.removeItem('pii.ibanNumberField')
          localStorage.removeItem('pii.routingNumberField')
          localStorage.removeItem('pii.swiftCodeField')
          localStorage.removeItem('pii.allowOtherNonValidatedTaxID')
          localStorage.removeItem('pii.maxAttemptsTax')
          localStorage.removeItem('pii.maxAttemptsBank')
          localStorage.removeItem('pii.performNameComparison')
          localStorage.removeItem('pii.nameComparisonPassThreshold')
          localStorage.removeItem('pii.nameComparisonWarningThreshold')
          setCustomBankFields([])
          setLatestFileUsed(null)
          setEncryptedAndSent(true)
        } else {
          console.log('Error on Finalizing Form')
        }
      } else {
        // errorMessage = validationMessage + response.message
        setValidationMessage(response.message)
      }
      setShowLoading(false)
    }
  }

  async function processFile(client, environment, validationId) {
    if (file && file.length !== 0) {
      let newValidationStatus = { ...validationStatus }
      newValidationStatus[piiValidationTypes.file] = true
      let newStatus = { ...status }
      newStatus.file = true

      setShowLoading(true)
      const response = await processPIIFile(client, environment, validationId, file[0], urlToken)
      if (response.success) {
        setHideUploadFile(true)

        setShowLoading(true)
        setFileIsLoading(true)
        setValidationStatus(newValidationStatus)
        setStatus(newStatus)
        setTimeout(() => {
          getFileProcessStatus(response.fileId, 1)
        }, timerReviewFileProcess)
      } else {
        setFileMessage('An error ocurrs, please try again')
      }

      setShowLoading(false)
    }
  }

  const handleClickShowPassword = () => setShowPassword(!showPassword)
  const handleMouseDownPassword = () => setShowPassword(!showPassword)

  async function getFileProcessStatus(fileId, fileRetryCount) {
    const response = await getFormProcessSatus(fileId, clientId, environmentId, urlToken)
    let foundType = ''
    if (response.success) {
      let acceptableTin = false
      let acceptableName = true

      const { type, ssn, name } = response
      foundType = type
      setTin(ssn)

      if (foundType === '' || foundType === 'ssn') {
        setSupplierType('Individual')
        if (
          bankAccountOwnershipValidationEnabled === 'true' &&
          ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE && bankAccountOwnershipConfigurations?.enabled) ||
            (bankAccountOwnershipConfigurations === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled))
        ) {
          const { firstName, lastName } = divideNameIntoFirstAndLast(name)
          setFirstName(firstName)
          setLastName(lastName)
          if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
            setAccountHolderFirstName(firstName)
            setAccountHolderLastName(lastName)
          }
          acceptableName = false
        } else {
          setName(name)
          if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
            setAccountHolderLegalName(name)
          }
        }
      } else {
        setSupplierType('Organization')
        setName(name)
        if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
          setAccountHolderLegalName(name)
        }
      }

      nameComparisonFn(name, 'NAME')

      setUploadW8W9Form(false)
      setShowLoading(false)
      setFileIsLoading(false)
      setOtherValues(response.otherValues)
      setTaxInformationManuallyCompleted(false)

      if (name.length === 0 && (ssn.length === 0 || ssn === '--')) {
        setFileIsLoading(false)
        setFormDataErrorValues(true)
        setFormDataErrorMessage('No information was found for the processed file.')
        //setLatestFileUsed(null)
        if (ssn === '--' || ssn === '') {
          setTin(null)
        }
        //Enable Tin Sections
        if (startWithW9UploadForm === 'true') {
          console.log('set manual tin section')
          setTaxInformationManuallyCompleted(true)
        }
      } else {
        //Check name field
        if (name.length === 0) {
          setName('')
          setAccountHolderLegalName('')
          acceptableName = false
        }

        //Check regex for ein or ssn
        if (foundType === '' || foundType === 'ssn') {
          //Check regex for ssn
          if (countryTax === 'US' && piiValidationInputFormatMessages.tin.usSsnNumber.regex.test(ssn)) {
            acceptableTin = true
          } else if (countryTax !== 'US' && piiValidationInputFormatMessages.tin.nonUsTaxNumber.regex.test(ssn)) {
            acceptableTin = true
          }
        } else {
          //Check regex for ein
          if (countryTax === 'US' && piiValidationInputFormatMessages.tin.usEinNumber.regex.test(ssn)) {
            acceptableTin = true
          } else if (countryTax !== 'US' && piiValidationInputFormatMessages.tin.nonUsTaxNumber.regex.test(ssn)) {
            acceptableTin = true
          }
        }

        setTaxInformationManuallyCompleted(!acceptableTin)
        setTinInvalidFormat(!acceptableTin)

        if (!acceptableTin || !acceptableName) {
          console.log('set manual tin section')
          setTaxInformationManuallyCompleted(true)
          setFormDataErrorValues(true)
          setFormDataErrorMessage('Information was found in the processed file, but the form may require manual modifications.')
        }
      }
    } else {
      fileRetryCount += 1
      if (fileRetryCount <= maxRetriesFileProcess) {
        setTimeout(() => {
          getFileProcessStatus(fileId, fileRetryCount)
        }, timerReviewFileProcess)
      } else {
        //console.log('process could not be verified')
        setTin(null)
        setName(null)
        setFirstName(null)
        setLastName(null)
        setAccountHolderLegalName(null)
        setAccountHolderFirstName(null)
        setAccountHolderLastName(null)
        setUploadW8W9Form(false)
        setFormDataErrorValues(true)
        setFormDataErrorMessage('No information was found for the processed file.')
        setFileIsLoading(false)
        //setLatestFileUsed(null)
        //Enable Tin Sections
        if (startWithW9UploadForm === 'true') {
          console.log('set manual tin section')
          setTaxInformationManuallyCompleted(true)
        }
      }
    }
  }

  async function saveSupplierData(client, environment, validationId, accountHolderNameMismatch) {
    let response
    let updatedValidation = ''
    let isTaxManualCompleted = false
    let nameMismatch = undefined

    if (startWithW9UploadForm === 'false' || startWithW9UploadForm === '') isTaxManualCompleted = true
    if (startWithW9UploadForm === 'true' && taxInformationManuallyCompleted) isTaxManualCompleted = true
    if (isFormUpdate && isFormUpdate === 'true') updatedValidation = formUpdateValidation

    let validationTypes = getValidationTypes()
    let isValidated = {
      giact: validationTypes.includes(piiValidationTypes.giact) ? validationStatus[piiValidationTypes.giact] && !giactTransactionBypass : null,
      bank: validationTypes.includes(piiValidationTypes.bank) ? validationStatus[piiValidationTypes.bank] && !bankTransactionBypass : null,
      bankAccountOwnership: validationTypes.includes(piiValidationTypes.bankAccountOwnership)
        ? validationStatus[piiValidationTypes.bankAccountOwnership] && !bankAccountOwnershipTransactionBypass
        : null,
      tin: validationTypes.includes(piiValidationTypes.tin)
        ? isNotSupportedCountry
          ? false
          : validationStatus[piiValidationTypes.tin] && !taxTransactionBypass
        : null
    }

    if (performNameComparison !== undefined && performNameComparison === 'true' && bistroPiiTransaction !== undefined) {
      nameMismatch = ![NameComparisonCodes.LEGAL_NAME.MATCH, NameComparisonCodes.DBA_NAME.MATCH].includes(comparisonNameResult?.code)
    }
    response = await saveSupplierInformation(
      client,
      environment,
      validationId,
      updatedValidation,
      countryTax,
      supplierType,
      taxNameSelected,
      name || [firstName, lastName].join(' ').trim(),
      tin,
      accountType !== '' ? accountType : undefined,
      accountNumber,
      requireBankAccountHolder === 'true' ? accountHolderLegalName || [accountHolderFirstName, accountHolderLastName].join(' ') : undefined,
      accountHolderNameMismatch,
      bankName,
      swiftCode,
      routingNumber,
      country,
      ibanNumber,
      customBankFields,
      isTaxManualCompleted,
      isValidated,
      nameMismatch,
      urlToken
    )
    return response
  }

  function updateValidationStatus(validation, newStat) {
    newStat = Boolean(newStat)

    console.log(`${validation} will update status to ${newStat}`)
    setValidationStatus(prevStatus => ({ ...prevStatus, [validation]: newStat }))
    console.log(`${validation} status is now ${validationStatus[validation]}`)
  }
  function getValidationTypes() {
    let validationTypes = []

    if (isFormUpdate && isFormUpdate === 'true') {
      if (formUpdateValidation.includes(piiValidationUpdateOptions.all)) {
        if (!disableTinSection || (sectionConfigurations && taxSectionEnabled === 'true')) {
          validationTypes.push(piiValidationTypes.tin)
        }
        if (sectionConfigurations && bankSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.bank)
        }
        if (bankAccountOwnershipValidationEnabled === 'true' && bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE) {
          validationTypes.push(piiValidationTypes.bankAccountOwnership)
        }
        if (bankAccountOwnershipValidationEnabled === 'true' && bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT) {
          validationTypes.push(piiValidationTypes.giact)
        }
        if (sectionConfigurations && attachmentSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.attachment)
        }
      }

      if (formUpdateValidation.includes(piiValidationUpdateOptions.tin)) {
        if (!disableTinSection || (sectionConfigurations && taxSectionEnabled === 'true')) {
          validationTypes.push(piiValidationTypes.tin)
        }
      }

      if (formUpdateValidation.includes(piiValidationUpdateOptions.bank)) {
        if (sectionConfigurations && bankSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.bank)
        }
        if (bankAccountOwnershipValidationEnabled === 'true' && bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE) {
          validationTypes.push(piiValidationTypes.bankAccountOwnership)
        }
        if (bankAccountOwnershipValidationEnabled === 'true' && bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT) {
          validationTypes.push(piiValidationTypes.giact)
        }
      }

      if (formUpdateValidation.includes(piiValidationUpdateOptions.attachment)) {
        if (sectionConfigurations && attachmentSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.attachment)
        }
      }
    } else {
      if (sectionConfigurations) {
        if (taxSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.tin)
        }
        if (bankSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.bank)
        }
        if (bankAccountOwnershipValidationEnabled === 'true' && bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE) {
          validationTypes.push(piiValidationTypes.bankAccountOwnership)
        }
        if (bankAccountOwnershipValidationEnabled === 'true' && bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT) {
          validationTypes.push(piiValidationTypes.giact)
        }
        if (attachmentSectionEnabled === 'true') {
          validationTypes.push(piiValidationTypes.attachment)
        }
      }
    }

    return validationTypes
  }
  //block supplier after max retries
  async function blockSupplier() {
    // console.log('block user')

    let message = ''
    //request to block Pii Supplier
    setShowLoading(true)
    await blockPIISupplier(clientId, environmentId, validationId, urlToken)
    //test
    //response = await blockPIISupplier('nitorDevTest-PIITest', 'TSK3160419884')
    setShowLoading(false)

    //console.log('block user response', { context: response })
    message = 'You’ve reached the maximum attempts.'
    //setValidationMessage(message)
    setPiiMessage(message)
    //setValidationMessageTin(message)
    setEncryptedAndSent(true)
  }
  async function validatePiiData(validationName) {
    const newTransaction = {
      source: 'pii',
      externalId: '',
      validations: []
    }
    let validated = false
    let hasInvalidFields = false
    if (bankRetryCount >= maxAttemptsBank || tinRetryCount >= maxAttemptsTax) {
      await blockSupplier()
      return
    }

    //build data
    switch (validationName) {
      case piiValidationTypes.bank:
        console.log('Validate Pii Data', {
          context: status,
          type: piiValidationTypes.bank,
          retry: bankRetryCount,
          currentStatus: status
        })

        if (
          bankAccountOwnershipValidationEnabled === 'true' &&
          ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE && bankAccountOwnershipConfigurations?.enabled) ||
            (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations?.enabled))
        ) {
          if (requireBankAccountHolder !== 'true') {
            if (taxValidationRequired === 'true' && !validationStatus[piiValidationTypes.tin]) {
              setValidationMessage('Bank validation cannot be completed. Please ensure tax information is verified or bypassed.')
              return
            }

            if (taxSectionEnabled === 'false') {
              setValidationMessage('Bank validation cannot be completed. Please ensure tax information is provided.')
              return
            }
          }

          if (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT) {
            if (giactConfigurations.parameters?.specificConfigurations?.requiredTax && (!accountHolderFirstName || !accountHolderLastName || !tin)) {
              setValidationMessage('Bank validation is not possible without the First Name, Last Name, and tax ID. Please provide these details.')
              return
            }

            if (!routingNumber) {
              setValidationMessage('Bank validation is not possible without the Routing Number. Please provide these details.')
              return
            }
          }

          if (supplierType === 'Individual' && (!accountHolderFirstName || !accountHolderLastName)) {
            setValidationMessage(
              'Bank validation is not possible without the Account Holder First Name and Account Holder Last Name. Please provide these details.'
            )
            return
          } else if (supplierType === 'Organizatiton' && !accountHolderLegalName) {
            setValidationMessage('Bank validation is not possible without the Account Holder Legal Name. Please provide these details.')
            return
          }

          if (!accountType) {
            setValidationMessage('Bank validation is not possible without the Account Type. Please provide these details.')
            return
          }
        }

        if (!status['bankName']) {
          setBankName('')
          hasInvalidFields = true
        }

        if (mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true') {
          if ((routingNumberField === undefined || routingNumberField === 'false') && (swiftCodeField === undefined || swiftCodeField === 'false')) {
            // default check
            if (!status['routingNumber'] && !status['swiftCode']) {
              setRoutingNumber('')
              setSwiftCode('')
              hasInvalidFields = true
            }
          } else {
            // Check mandatory fields
            if (routingNumberField && routingNumberField === 'true') {
              if (!status['routingNumber']) {
                setRoutingNumber('')
                hasInvalidFields = true
              }
            }

            if (swiftCodeField && swiftCodeField === 'true') {
              if (!status['swiftCode']) {
                setSwiftCode('')
                hasInvalidFields = true
              }
            }
          }

          if (
            (accountNumberField === undefined || accountNumberField === 'false') &&
            (ibanNumberField === undefined || ibanNumberField === 'false')
          ) {
            // default check
            if (!status['accountNumber'] && !status['ibanNumber']) {
              setAccountNumber('')
              setIbanNumber('')
              hasInvalidFields = true
            }
          } else {
            // Check mandatory fields
            if (accountNumberField && accountNumberField === 'true') {
              if (!status['accountNumber']) {
                setAccountNumber('')
                hasInvalidFields = true
              }
            }

            if (ibanNumberField && ibanNumberField === 'true') {
              if (!status['ibanNumber']) {
                setIbanNumber('')
                hasInvalidFields = true
              }
            }
          }
        }

        // Custom Bank Fields
        if (!validateCustomBankFields()) {
          hasInvalidFields = true
        }

        if (hasInvalidFields) {
          return
        }

        newTransaction.externalId = bistroPiiTransaction ? bistroPiiTransaction : `${validationId || ''}-${supplierId || ''}`

        const selectedCountry = countryListOptions.filter(x => x.name === country)[0].iso2
        const routingNumberInput = routingNumber || undefined
        const swiftCodeInput = swiftCode || undefined

        const newBankValidation = {
          type: 'bank',
          input: {
            name: bankName,
            country: selectedCountry,
            externalId: `attempt-${bankRetryCount}`,
            swiftCode: swiftCodeInput,
            routingNumber: routingNumberInput
          },
          prevalidationCodes: []
        }

        newTransaction.validations.push(newBankValidation)

        if (bankAccountOwnershipValidationEnabled === 'true') {
          switch (bankAccountOwnershipValidationProvider) {
            case BankAccountOwnershipProvider.DATA_ASSURE: {
              if (bankAccountOwnershipConfigurations?.enabled) {
                const newBankAccountOwnershipValidation = {
                  type: 'bankAccountOwnership',
                  input: {
                    externalId: `attempt-${bankRetryCount}`,
                    accountType: accountType || undefined,
                    accountNumber: accountNumber || undefined,
                    country: selectedCountry,
                    routingNumber: routingNumberInput,
                    swiftCode: swiftCodeInput,
                    accountOwner: {
                      isOrganization: supplierType === 'Organization',
                      firstName: accountHolderFirstName || undefined,
                      lastName: accountHolderLastName || undefined,
                      legalName: accountHolderLegalName || undefined,
                      taxId: tin || undefined
                    }
                  }
                }

                newTransaction.validations.push(newBankAccountOwnershipValidation)
              }
              break
            }
            case BankAccountOwnershipProvider.GIACT: {
              if (giactConfigurations.enabled) {
                const newGiactValidation = {
                  type: 'giact',
                  input: {
                    externalId: `attempt-${bankRetryCount}`,
                    routingNumber: routingNumberInput,
                    accountNumber: accountNumber || undefined,
                    accountType: accountType || undefined,
                    country: selectedCountry,
                    accountHolder: {
                      firstName: accountHolderFirstName || undefined,
                      lastName: accountHolderLastName || undefined,
                      companyName: accountHolderLegalName || undefined,
                      isOrganization: supplierType === 'Organization',
                      tin: tin || undefined
                    }
                  }
                }

                newTransaction.validations.push(newGiactValidation)
              }
              break
            }
          }
        }

        break
      case piiValidationTypes.tin:
        console.log('Validate Pii Data', {
          context: status,
          type: piiValidationTypes.tin,
          retry: bankRetryCount,
          currentStatus: status
        })
        setHideUploadFile(true)

        if (
          bankAccountOwnershipValidationEnabled === 'true' &&
          ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE && bankAccountOwnershipConfigurations?.enabled) ||
            (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled))
        ) {
          if (supplierType === 'Individual') {
            if (!status.firstName) {
              setFirstName('')
              setAccountHolderFirstName('')
              hasInvalidFields = true
            }
            if (!status.lastName) {
              setLastName('')
              setAccountHolderLastName('')
              hasInvalidFields = true
            }
          } else if (supplierType === 'Organization' && !status.name) {
            setName('')
            setAccountHolderLegalName('')
            hasInvalidFields = true
          }
        } else if (!status.name) {
          setName('')
          setAccountHolderLegalName('')
          hasInvalidFields = true
        }

        if (!status['tin']) {
          setTin('')
          setFormDataErrorMessage('TIN is required')
          hasInvalidFields = true
        }

        if (hasInvalidFields) {
          return
        }

        const inputName = name || [firstName, lastName].join(' ')

        const newTaxValidation = {
          type: 'tax',
          input: {
            externalId: `attempt-${tinRetryCount}`,
            tin: tin !== '' ? tin : undefined,
            taxName: taxNameSelected || '',
            firstName: '',
            lastName: inputName,
            companyName: inputName,
            country: countryTax,
            isOrganization: supplierType === 'Organization'
          },
          prevalidationCodes: []
        }

        newTransaction.externalId = bistroPiiTransaction ? bistroPiiTransaction : `${validationId || ''}-${supplierId || ''}`
        newTransaction.validations.push(newTaxValidation)
        break
      default:
        break
    }
    setShowLoading(true)
    setValidationMessage(null)
    setValidationMessageTin(null)
    setWarningValidationMessage(null)
    setFormDataErrorValues(false)
    setFormDataErrorMessage(null)

    if (validationName === piiValidationTypes.tin && (tinAlwaysPass || (allowOtherNonValidatedTaxID && taxNameSelected === 'Other'))) {
      setShowLoading(false)
      validated = true
      updateValidationStatus(validationName, validated)
      setTaxTransactionBypass(true)
      return
    }

    if (validationName === piiValidationTypes.bank && swiftCode === '' && routingNumber === '') {
      setShowLoading(false)
      validated = true
      updateValidationStatus(validationName, validated)
      setBankTransactionBypass(true)
      return
    }

    mutateBistroValidation({ appId: environmentId, data: newTransaction, validationType: validationName, urlToken: urlToken })
  }

  function bypassValidation(validation) {
    switch (validation) {
      case piiValidationTypes.tin:
        updateValidationStatus(piiValidationTypes.tin, true)
        setTaxTransactionBypass(true)
        break

      default:
        break
    }
  }

  function isBankValidationButtonDisabled() {
    return !validateInputFields(piiValidationTypes.bank) || isProcessingBank || isSupplierNameMismatch
  }

  function isTaxValidationButtonDisabled() {
    return !validateInputFields(piiValidationTypes.tin) || isProcessingTin || isSupplierNameMismatch
  }

  function onDeleteAdditionalFile(file) {
    let newFileArray = [...additionalFile]
    newFileArray = newFileArray.filter(item => item.name !== file.name)
    setAdditionalFile(newFileArray)
    if (newFileArray.length < minAdditionalFiles) {
      let newValidationStatus = { ...validationStatus }
      newValidationStatus[piiValidationTypes.attachment] = false
      setValidationStatus(newValidationStatus)
    }
    if (newFileArray.length === 0) setAdditionalFileMessage([...'Please select a file'])
  }
  async function processAdditionalFile(client, environment, validationId) {
    if (additionalFilesMaxCount < 10) {
      if (additionalFile && additionalFile.length !== 0) {
        setShowLoading(true)
        setAdditionalFileIsUploading(true)
        let response
        let index = 0
        for (index = 0; index < additionalFile.length; index++) {
          const itemFile = additionalFile[index]
          response = await encryptAdditionalFile(client, environment, validationId, itemFile, urlToken)
        }
        if (response.success) {
          if (minAdditionalFiles > 0 && additionalfileMessage.length >= minAdditionalFiles && !validationStatus[piiValidationTypes.attachment]) {
            let newValidationStatus = { ...validationStatus }
            newValidationStatus[piiValidationTypes.attachment] = true
            setValidationStatus(newValidationStatus)
          }
          setAdditionalFileUploadResult(`${additionalFile.length === 0 ? 'File' : 'Files'} successfully uploaded`)
          setTimeout(() => {
            setAdditionalFileUploadResult('')
          }, 3000)
        } else {
          setFileMessage('An error occurred, please try again')
        }
        setShowLoading(false)
        setAdditionalFileIsUploading(false)
      }
    } else {
      setWarningAdditionalFileMessage('Max Additional Files reached.')
    }
  }

  function setAdditionalFileUploaded(files) {
    console.log('adding file', { context: files })
    setAdditionalFilesMaxCount(files.length)
    let finalMessages = files.map(file => {
      return file.name
    })
    setAdditionalFile([...files])
    setAdditionalFileMessage(finalMessages)
    let lastFile = files[files.length - 1]
    if (lastFile && lastFile[0]) {
      setAdditionalFileSelected(true)
      //setAdditionalFileMessage(lastFile[0].name)
    } else {
      setAdditionalFileSelected(false)
    }
    console.log('current files used', {
      context: {
        additionalFilesMaxCount,
        maxAdditionalFiles
      }
    })
  }

  function returnForm() {
    return (
      <div className={classes.container} style={{ paddingTop: '0%' }}>
        {showLoading ? <Spinner /> : ''}
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={12} md={12} lg={8}>
            <Card className={classes.card}>
              <CardHeader className={`${classes.cardHeader} ${classes.textCenter}`} color="primary" style={{ paddingTop: '0%' }}>
                <h3 className={classes.cardTitle}>Sensitive Data Entry and Verification Form</h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="position:absolute" justifyContent="center">
                  <GridItem xs={12}>
                    <h5 style={{ textAlign: 'center' }}>
                      <strong>
                        For Supplier: {supplierName} ({supplierId})
                      </strong>
                    </h5>
                  </GridItem>
                  <GridItem xs={12}>
                    <div className={wizardClasses.right}>
                      {isSupplierNameMismatch ? (
                        <Tooltip title={'Cancel PII Registration Process'}>
                          <Button
                            color={'danger'}
                            onClick={() => {
                              confirmCancellationPiiForm()
                            }}
                            className={classes.documentation}
                          >
                            Cancel
                          </Button>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                      <Tooltip title={'Reset section data.'}>
                        <Button
                          color={'primary'}
                          onClick={() => {
                            resetFields('')
                          }}
                          className={classes.documentation}
                        >
                          Reset
                        </Button>
                      </Tooltip>
                    </div>
                  </GridItem>
                </GridContainer>
                {/* Tax Section */}
                <GridContainer>{returnTaxSection()}</GridContainer>
                {/* Bank Section */}
                <GridContainer>{returnBankSection()}</GridContainer>
                {/* Attachment Section */}
                <GridContainer>{returnAttachmentSection()}</GridContainer>
              </CardBody>
              <div className={wizardClasses.footer}>
                <div className={wizardClasses.right}>
                  <Tooltip title={'Click to Validate '}>
                    <Button
                      color="primary"
                      disabled={!formStatus}
                      onClick={() => {
                        onSendData()
                      }}
                      round
                      className={classes.documentation}
                    >
                      Click to encrypt and send data
                    </Button>
                  </Tooltip>
                </div>
                <div className={wizardClasses.clearfix} />
              </div>
            </Card>
          </GridItem>
          <FieldsDetailsModal showModal={showOtherValues} onClose={() => setShowOtherValues(false)} modalInfo={otherValues} />
          <ConfirmAlertDialog
            openModal={openAlert}
            alertTitle={alertTitle}
            alertDescription={alertDescription}
            handleClose={alertCloseFn}
          ></ConfirmAlertDialog>
        </GridContainer>
        <Snackbar
          place="bl"
          color="danger"
          icon={AddAlert}
          message={errorMessage ? errorMessage : ''}
          open={!!errorMessage}
          closeNotification={() => {
            setErrorMessage(undefined)
          }}
          close
        />
        <ConfirmAlertDialog
          openModal={openAlert}
          alertTitle={alertTitle}
          alertDescription={alertDescription}
          handleClose={alertCloseFn}
        ></ConfirmAlertDialog>
      </div>
    )
  }

  function returnAttachmentSection() {
    const isSectionEnabled = sectionConfigurations && (attachmentSectionEnabled === 'true' || attachmentSectionEnabled === undefined)
    const isUpdateAll = isFormUpdate === 'true' && formUpdateValidation.includes(piiValidationUpdateOptions.all)
    const isUpdateAttachment = isFormUpdate === 'true' && formUpdateValidation.includes(piiValidationUpdateOptions.attachment)

    if (isFormUpdate && isFormUpdate === 'true') {
      if (!(isUpdateAll || isUpdateAttachment)) {
        return <></>
      }
    } else {
      if (!isSectionEnabled) {
        return <></>
      }
    }

    return (
      <>
        <GridItem xs={12} className="padding-bottom:5px">
          <h4>
            <strong>Additional Attachments</strong>
          </h4>
        </GridItem>
        <GridItem xs={12} className="padding-bottom:5px">
          <p>{additionalFileInstructions}</p>
          <h4
            style={{
              color: 'orange'
            }}
          >
            <span>{warningAdditionalFileMessage}</span>
          </h4>
          {minAdditionalFiles > 0 && !validationStatus[piiValidationTypes.attachment] ? (
            <p style={{ color: 'red', fontWeight: 'bold' }}>{`*Uploading a minimum of ${minAdditionalFiles} additional file(s) is required`}</p>
          ) : null}
        </GridItem>
        <GridItem xs={12}>
          <div
            style={{
              display: 'inline-flex'
            }}
          >
            <ol>
              {additionalfileMessage.map(file => {
                return <li>{file}</li>
              })}
            </ol>
          </div>
        </GridItem>
        <GridItem xs={12}>
          <GridContainer justifyContent="center">
            <div style={{ width: '50%' }}>
              <DropzoneArea
                dropzoneText={'Drag and drop your file here or click.'}
                dropzoneProps={{ disabled: isSupplierNameMismatch }}
                onChange={files => {
                  setAdditionalFileUploaded(files)
                }}
                onDelete={deleted => {
                  onDeleteAdditionalFile(deleted)
                  setAdditionalFileSelected(false)
                }}
                filesLimit={Number(maxAdditionalFiles)}
                maxFileSize={1000000}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNamesInPreview={true}
              />
            </div>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          <div className={wizardClasses.right}>
            <Tooltip title={'Click to process file '}>
              <Button
                color={additionalFileIsUploading ? 'danger' : 'primary'}
                onClick={() => {
                  processAdditionalFile(clientId, environmentId, validationId)
                  //process Additional File to encrypt
                  setAdditionalFileSelected(true)
                }}
                round
                className={classes.documentation}
                disabled={additionalFileIsUploading || isSupplierNameMismatch}
              >
                {!additionalFileSelected ? (
                  'Upload File'
                ) : additionalFileSelected ? (
                  additionalFileIsUploading ? (
                    <div>
                      <CheckBox className={classes.icons} />
                      Processing File
                    </div>
                  ) : (
                    <div>
                      <Cancel className={classes.icons} />
                      Upload File
                    </div>
                  )
                ) : (
                  <div>...</div>
                )}
              </Button>
            </Tooltip>
          </div>
          <div>
            <h6
              style={{
                color: 'red',
                hidden: additionalFileUploadResult.length === 0
              }}
            >
              <strong>{additionalFileUploadResult}</strong>
            </h6>
          </div>
        </GridItem>
      </>
    )
  }
  function returnTaxSection() {
    const isSectionEnabled = sectionConfigurations && (taxSectionEnabled === 'true' || taxSectionEnabled === undefined)
    const isUpdateAll = isFormUpdate === 'true' && formUpdateValidation.includes(piiValidationUpdateOptions.all)
    const isUpdateTIN = isFormUpdate === 'true' && formUpdateValidation.includes(piiValidationUpdateOptions.tin)

    if (isFormUpdate && isFormUpdate === 'true') {
      if (!(isUpdateAll || isUpdateTIN)) {
        return <></>
      }
    } else {
      if (!isSectionEnabled) {
        return <></>
      }
    }

    return (
      <>
        <GridItem xs={12}>
          <h5>
            <strong>Personal Tax ID Info</strong>
          </h5>
        </GridItem>
        <GridItem xs={12} hidden={uploadTaxDocumentationEnabled !== 'true'}>
          <FormControlLabel
            control={
              <Switch
                checked={uploadTaxDocumentationChecked}
                onChange={event => setUploadTaxDocumentationChecked(event.target.checked)}
                classes={{
                  switchBase: buttonClases.switchBase,
                  checked: buttonClases.switchChecked,
                  thumb: buttonClases.switchIcon,
                  track: buttonClases.switchBar
                }}
                disabled={tinRetryCount < 1 || validationStatus[piiValidationTypes.tin]}
              />
            }
            classes={{
              label: buttonClases.label
            }}
            label="Upload Tax Documentation"
            labelPlacement="start"
          />
        </GridItem>
        <GridItem xs={12} hidden={!uploadTaxDocumentationChecked}>
          <GridContainer justifyContent="center">
            <div style={{ width: '50%' }}>
              <DropzoneArea
                key={taxDocumentationDropzoneAreaKey}
                dropzoneClass={(isTaxValidationButtonDisabled() && dropzoneAreaClasses.disabledState) || null}
                dropzoneProps={{
                  disabled: isTaxValidationButtonDisabled()
                }}
                dropzoneText={'Drag and drop your tax documentation here or click.'}
                onChange={files => {
                  setTaxDocumentationFiles(files)
                  if (files.length) {
                    setTaxTransactionBypass(true)
                    updateValidationStatus(piiValidationTypes.tin, true)
                  } else {
                    setTaxTransactionBypass(false)
                    updateValidationStatus(piiValidationTypes.tin, false)
                  }
                }}
                filesLimit={1}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNamesInPreview={true}
                showFileNames={true}
              />
            </div>
          </GridContainer>
        </GridItem>
        <GridItem xs={12}>
          {isLoadingCountryKi ? (
            <CircularProgress />
          ) : (
            <FormControl>
              <InputLabel id="tax-country-select-label">Tax Country*</InputLabel>
              <Select
                labelId="tax-country-select-label"
                id="tax-country-select"
                label="Tax Country"
                value={countryTax}
                disabled={disableCountryTax || validationStatus[piiValidationTypes.tin] || isProcessingTin}
                onChange={e => {
                  setCountryTax(e.target.value)
                }}
                style={{ minWidth: '340px', float: 'left' }}
              >
                {countryTaxListOptions.map(value => (
                  <MenuItem value={value.iso2} key={value.iso2}>
                    {value.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id="my-helper-text">{countryTaxSelectHelp}</FormHelperText>
            </FormControl>
          )}
        </GridItem>
        <GridItem xs={12} hidden={disableTinSection}>
          <GridContainer>
            <GridItem xs={12} className="padding-bottom:5px">
              <h6
                style={{
                  color: 'red',
                  hidden: validationStatus[piiValidationTypes.tin],
                  whiteSpace: 'pre-line'
                }}
              >
                <strong>{validationMessageTin}</strong>
              </h6>
              <h6
                style={{
                  color: 'red',
                  hidden: !formDataErrorValues
                }}
              >
                <strong>{formDataErrorMessage}</strong>
              </h6>
              {isSupplierNameMismatch && !uploadW8W9Form ? (
                <h6
                  style={{
                    color: 'red',
                    whiteSpace: 'pre-line'
                  }}
                >
                  <strong>{comparisonNameMessage}</strong>
                </h6>
              ) : (
                <></>
              )}
            </GridItem>
            <GridItem xs={12} hidden={hideUploadFile}>
              <FormControlLabel
                control={
                  <Switch
                    checked={uploadW8W9Form}
                    onChange={event => setUploadW8W9Form(event.target.checked)}
                    classes={{
                      switchBase: buttonClases.switchBase,
                      checked: buttonClases.switchChecked,
                      thumb: buttonClases.switchIcon,
                      track: buttonClases.switchBar
                    }}
                  />
                }
                classes={{
                  label: buttonClases.label
                }}
                label="Upload W8/W9 Form"
                labelPlacement="start"
              />
            </GridItem>
            <GridItem xs={12} hidden={!uploadW8W9Form}>
              <div
                style={{
                  display: 'inline-flex'
                }}
              >
                {fileSelected ? <AttachFileOutlinedIcon style={{ color: 'primary' }} /> : <ClearOutlinedIcon style={{ color: 'red' }} />}
                {fileMessage}
              </div>
            </GridItem>
            <GridItem xs={12} hidden={!uploadW8W9Form}>
              <GridContainer justifyContent="center">
                {fileIsLoading ? (
                  <div className={classes.reloadSize} style={{ alignItems: 'center' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <div style={{ width: '50%' }}>
                    <DropzoneArea
                      acceptedFiles={['.pdf']}
                      dropzoneText={'Drag and drop your form here or click.'}
                      onChange={files => {
                        setFileUploaded(files)
                      }}
                      onDelete={deleted => {
                        setFileSelected(false)
                        let newStatus = { ...status }
                        newStatus.file = false
                        setStatus(newStatus)
                        setFileMessage('Please select a file')
                      }}
                      filesLimit={1}
                      showPreviews={false}
                      showPreviewsInDropzone={true}
                      showFileNamesInPreview={true}
                    />
                  </div>
                )}
              </GridContainer>
            </GridItem>
            <GridItem xs={12} hidden={!uploadW8W9Form}>
              <div className={wizardClasses.right}>
                <Tooltip title={'Click to process file '}>
                  <Button
                    color={!fileSelected ? 'primary' : validationStatus[piiValidationTypes.file] ? 'info' : 'danger'}
                    onClick={() => {
                      processFile(clientId, environmentId, validationId)
                      setFileSelected(true)
                    }}
                    round
                    className={classes.documentation}
                    disabled={fileSelected && validationStatus[piiValidationTypes.file]}
                  >
                    {!fileSelected ? (
                      'Click To Process File'
                    ) : fileSelected ? (
                      validationStatus[piiValidationTypes.file] ? (
                        <div>
                          <CheckBox className={classes.icons} />
                          Processing File
                        </div>
                      ) : (
                        <div>
                          <Cancel className={classes.icons} />
                          Click To Process File
                        </div>
                      )
                    ) : (
                      <div>...</div>
                    )}
                  </Button>
                </Tooltip>
              </div>
            </GridItem>
            <GridItem xs={12} hidden={!otherValues}>
              <div className={wizardClasses.left}>
                <Tooltip title={'Click to show other fields found'}>
                  <a href="# " onClick={showTinFileOtherValues}>
                    Click To show other fields found
                  </a>
                </Tooltip>
              </div>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={6} hidden={uploadW8W9Form}>
              <FormControl className={customClasses.formControl}>
                <InputLabel id="supplier-type-select-label">Supplier Type*</InputLabel>
                <Select
                  labelId="supplier-type-select-label"
                  id="supplier-type-select"
                  label="Supplier Type"
                  MenuProps={{ style: { zIndex: 5555 } }}
                  disabled={(() => {
                    let val = false
                    if (validationStatus[piiValidationTypes.tin] || isProcessingTin) {
                      return true
                    }

                    if (disableTinSection) val = true

                    if (!taxTransactionFailed) {
                      if (startWithW9UploadForm === 'true') {
                        val = hideUploadFile && !taxInformationManuallyCompleted
                      } else {
                        if (taxInformationManuallyCompleted) {
                          val = false
                        }
                      }
                    } else {
                      val = false
                    }

                    return val
                  })()}
                  style={{ minWidth: '340px', float: 'left' }}
                  value={supplierType}
                  onChange={e => {
                    const newSupplierType = e.target.value
                    setSupplierType(newSupplierType)

                    if (
                      bankAccountOwnershipValidationEnabled === 'true' &&
                      ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE &&
                        bankAccountOwnershipConfigurations?.enabled) ||
                        (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled))
                    ) {
                      if (newSupplierType === 'Individual' && name) {
                        const { firstName, lastName } = divideNameIntoFirstAndLast(name)
                        setFirstName(firstName)
                        setLastName(lastName)
                        setName(null)
                        if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
                          setAccountHolderFirstName(firstName)
                          setAccountHolderLastName(lastName)
                          setAccountHolderLegalName(null)
                        }
                      } else if (firstName || lastName) {
                        setName([firstName, lastName].join(' '))
                        setFirstName(null)
                        setLastName(null)
                        if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
                          setAccountHolderLegalName([firstName, lastName].join(' '))
                          setAccountHolderFirstName(null)
                          setAccountHolderLastName(null)
                        }
                      }
                    }
                  }}
                >
                  <MenuItem value={'Individual'} key={'Individual'}>
                    {'Individual'}
                  </MenuItem>
                  <MenuItem value={'Organization'} key={'Organization'}>
                    {'Organization'}
                  </MenuItem>
                </Select>
                <FormHelperText id="my-helper-text"></FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={6} hidden={uploadW8W9Form}>
              <FormControl className={customClasses.formControl}>
                <InputLabel id="tax-name-select-label">Tax Type*</InputLabel>
                <Select
                  labelId="tax-name-select-label"
                  id="tax-name-select"
                  label="Tax Type"
                  MenuProps={{ style: { zIndex: 5555 } }}
                  disabled={(() => {
                    let val = false
                    if (validationStatus[piiValidationTypes.tin] || isProcessingTin) {
                      return true
                    }

                    if (disableTinSection) val = true

                    if (!taxTransactionFailed) {
                      if (startWithW9UploadForm === 'true') {
                        val = hideUploadFile && !taxInformationManuallyCompleted
                      } else {
                        if (taxInformationManuallyCompleted) {
                          val = false
                        }
                      }
                    } else {
                      val = false
                    }

                    return val
                  })()}
                  style={{ minWidth: '340px', float: 'left' }}
                  value={taxNameSelected}
                  onChange={e => setTaxNameSelected(e.target.value)}
                >
                  {taxNamesForSelect.map(tn => (
                    <MenuItem key={tn.taxName} value={tn.taxName}>
                      <Tooltip
                        title={tn.taxNameVariants ? tn.taxNameVariants.join(', ') : ''}
                        placement="right"
                        PopperProps={{ className: customClasses.tooltipPopper }}
                      >
                        <span style={{ width: '50%' }}>{tn.taxName.toUpperCase()}</span>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText id="my-helper-text"></FormHelperText>
              </FormControl>
            </GridItem>
            <GridItem
              xs={12}
              hidden={
                uploadW8W9Form ||
                (supplierType === 'Individual' &&
                  bankAccountOwnershipValidationEnabled === 'true' &&
                  ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE &&
                    bankAccountOwnershipConfigurations?.enabled) ||
                    (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled)))
              }
            >
              <CustomInput
                labelText={`Name${isNotSupportedCountry ? '' : '*'}`}
                labelProps={{
                  style: { width: '100%', float: 'left' }
                }}
                id="name"
                formControlProps={{
                  fullWidth: true
                }}
                required
                inputProps={{
                  type: 'text',
                  value: name,
                  disabled: (() => {
                    let val = false
                    if (validationStatus[piiValidationTypes.tin] || isProcessingTin) {
                      return true
                    }
                    if (disableTinSection) val = true
                    if (!taxTransactionFailed) {
                      if (startWithW9UploadForm === 'true') {
                        val = hideUploadFile && !taxInformationManuallyCompleted
                      } else {
                        if (taxInformationManuallyCompleted) {
                          val = false
                        }
                      }
                    } else {
                      val = false
                    }
                    return val
                  })(),
                  onChange: event => {
                    setName(event.target.value)
                    if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
                      setAccountHolderLegalName(event.target.value)
                    }
                    nameComparisonFn(event.target.value, 'NAME')
                  },
                  endAdornment: !fileSelected ? (
                    ''
                  ) : status.name || isNotSupportedCountry ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  )
                }}
              />
              {name?.length === 0 ? (
                <FormHelperText id="ssn-value-1" error={true}>
                  {`"Name" is required`}
                </FormHelperText>
              ) : (
                ''
              )}
            </GridItem>
            {supplierType === 'Individual' &&
            bankAccountOwnershipValidationEnabled === 'true' &&
            ((bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE && bankAccountOwnershipConfigurations?.enabled) ||
              (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled)) ? (
              <>
                <GridItem xs={12} sm={12} md={12} lg={6} xl={6} hidden={uploadW8W9Form}>
                  <CustomInput
                    labelText={`First Name${isNotSupportedCountry ? '' : '*'}`}
                    labelProps={{
                      style: { width: '100%', float: 'left' }
                    }}
                    id="firstName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    required
                    inputProps={{
                      type: 'text',
                      value: firstName,
                      disabled: (() => {
                        let val = false
                        if (validationStatus[piiValidationTypes.tin] || isProcessingTin) {
                          return true
                        }
                        if (disableTinSection) val = true
                        if (startWithW9UploadForm === 'true') {
                          val = hideUploadFile && !taxInformationManuallyCompleted
                        } else {
                          if (taxInformationManuallyCompleted) {
                            val = false
                          }
                        }
                        return val
                      })(),
                      onChange: event => {
                        setFirstName(event.target.value)
                        if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
                          setAccountHolderFirstName(event.target.value)
                        }
                        nameComparisonFn(event.target.value, 'FIRST_NAME')
                      },
                      endAdornment: !fileSelected ? (
                        ''
                      ) : status.firstName || isNotSupportedCountry ? (
                        <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                      ) : (
                        <ClearOutlinedIcon style={{ color: 'red' }} />
                      )
                    }}
                  />
                  {firstName?.length === 0 ? (
                    <FormHelperText id="ssn-value-1" error={true}>
                      {`"First Name" is required`}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={6} xl={6} hidden={uploadW8W9Form}>
                  <CustomInput
                    labelText={`Last Name${isNotSupportedCountry ? '' : '*'}`}
                    labelProps={{
                      style: { width: '100%', float: 'left' }
                    }}
                    id="lastName"
                    formControlProps={{
                      fullWidth: true
                    }}
                    required
                    inputProps={{
                      type: 'text',
                      value: lastName,
                      disabled: (() => {
                        let val = false
                        if (validationStatus[piiValidationTypes.tin] || isProcessingTin) {
                          return true
                        }
                        if (disableTinSection) val = true
                        if (startWithW9UploadForm === 'true') {
                          val = hideUploadFile && !taxInformationManuallyCompleted
                        } else {
                          if (taxInformationManuallyCompleted) {
                            val = false
                          }
                        }
                        return val
                      })(),
                      onChange: event => {
                        setLastName(event.target.value)
                        if (!(validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch)) {
                          setAccountHolderLastName(event.target.value)
                        }
                        nameComparisonFn(event.target.value, 'LAST_NAME')
                      },
                      endAdornment: !fileSelected ? (
                        ''
                      ) : status.lastName || isNotSupportedCountry ? (
                        <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                      ) : (
                        <ClearOutlinedIcon style={{ color: 'red' }} />
                      )
                    }}
                  />
                  {lastName?.length === 0 ? (
                    <FormHelperText id="ssn-value-1" error={true}>
                      {`"Last Name" is required`}
                    </FormHelperText>
                  ) : (
                    ''
                  )}
                </GridItem>
              </>
            ) : null}
            <GridItem xs={12} hidden={uploadW8W9Form}>
              <CustomInput
                labelText={
                  countryTax === 'US'
                    ? supplierType === 'Individual'
                      ? 'Social Security Number*'
                      : 'Employer Identification Number*'
                    : 'Tax Identification Number (TIN)*'
                }
                labelProps={{
                  style: { width: '100%', float: 'left' }
                }}
                id="tin"
                formControlProps={{
                  fullWidth: true
                }}
                required
                inputProps={{
                  type: showPassword ? 'text' : 'password',
                  value: tin,
                  disabled: (() => {
                    let val = false
                    if (validationStatus[piiValidationTypes.tin] || isProcessingTin) {
                      return true
                    }
                    if (disableTinSection) val = true
                    if (!taxTransactionFailed) {
                      if (startWithW9UploadForm === 'true') {
                        val = hideUploadFile && !taxInformationManuallyCompleted
                      } else {
                        if (taxInformationManuallyCompleted) {
                          val = false
                        }
                      }
                    } else {
                      val = false
                    }
                    return val
                  })(),
                  onChange: event => {
                    let acceptable = false
                    setTin(event.target.value)

                    if (countryTax === 'US') {
                      if (supplierType === 'Individual') {
                        //Check SSN format
                        if (piiValidationInputFormatMessages.tin.usSsnNumber.regex.test(event.target.value)) {
                          acceptable = true
                          setTin(event.target.value)
                        }
                      }
                      //Check EIN Format
                      else if (piiValidationInputFormatMessages.tin.usEinNumber.regex.test(event.target.value)) {
                        acceptable = true
                        setTin(event.target.value)
                      }
                    } else if (piiValidationInputFormatMessages.tin.nonUsTaxNumber.regex.test(event.target.value)) {
                      acceptable = true
                      setTin(event.target.value)
                    }

                    setTinInvalidFormat(!acceptable)
                  },
                  endAdornment: !fileSelected ? (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                      <CheckCircleOutlinedIcon style={{ color: status.tin || isNotSupportedCountry ? 'green' : 'red' }} />
                    </InputAdornment>
                  )
                }}
              />
              {tinInvalidFormat && tin?.length > 0 ? (
                <FormHelperText id="tin-value-1" error={true}>
                  {`${
                    countryTax === 'US'
                      ? supplierType === 'Individual'
                        ? piiValidationInputFormatMessages.tin.usSsnNumber.message
                        : piiValidationInputFormatMessages.tin.usEinNumber.message
                      : piiValidationInputFormatMessages.tin.nonUsTaxNumber.message
                  }`}
                </FormHelperText>
              ) : (
                ''
              )}
            </GridItem>
            <GridItem xs={12} hidden={uploadW8W9Form}>
              <div className={wizardClasses.right}>
                {isNotSupportedCountry ? (
                  <Tooltip title={'Click to Bypass Validation'}>
                    <Button
                      color={'warning'}
                      onClick={() => {
                        bypassValidation(piiValidationTypes.tin)
                        setFileSelected(true)
                      }}
                      disabled={
                        (name || [firstName, lastName].join(' ').trim()) && isSupplierNameMismatch ? true : validationStatus[piiValidationTypes.tin]
                      }
                      round
                      className={classes.documentation}
                    >
                      {validationStatus[piiValidationTypes.tin] ? (
                        <div>
                          <CheckBox className={classes.icons} /> Bypassed
                        </div>
                      ) : (
                        <div>
                          <CheckBox className={classes.icons} /> Click to Bypass Validation
                        </div>
                      )}
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title={'Click to Validate '}>
                    <Button
                      color={
                        validationStatus[piiValidationTypes.tin] && taxTransactionBypass
                          ? 'warning'
                          : validationStatus[piiValidationTypes.tin]
                          ? 'info'
                          : isProcessingTin
                          ? 'secondary'
                          : 'danger'
                      }
                      onClick={() => {
                        validatePiiData(piiValidationTypes.tin)
                        setFileSelected(true)
                      }}
                      disabled={
                        isTaxValidationButtonDisabled() ||
                        validationStatus[piiValidationTypes.tin] ||
                        (!allowOtherNonValidatedTaxID && taxNameSelected === 'Other')
                      }
                      round
                      className={classes.documentation}
                    >
                      {validationStatus[piiValidationTypes.tin] && taxTransactionBypass ? (
                        <div>
                          <CheckBox className={classes.icons} /> Bypassed
                        </div>
                      ) : validationStatus[piiValidationTypes.tin] ? (
                        <div>
                          <CheckBox className={classes.icons} /> Validated
                        </div>
                      ) : (
                        <>
                          {isProcessingTin ? (
                            <div>
                              <CircularProgress size={12} style={{ color: 'white', display: 'inline', marginRight: '7px', marginLeft: '-10px' }} />{' '}
                              Processing...
                            </div>
                          ) : (
                            <div>
                              <Cancel className={classes.icons} /> Click To Validate
                            </div>
                          )}
                        </>
                      )}
                    </Button>
                  </Tooltip>
                )}
              </div>
            </GridItem>
          </GridContainer>
        </GridItem>
      </>
    )
  }
  function returnBankSection() {
    const isSectionEnabled = sectionConfigurations && (bankSectionEnabled === 'true' || bankSectionEnabled === undefined)
    const isUpdateAll = isFormUpdate === 'true' && formUpdateValidation.includes(piiValidationUpdateOptions.all)
    const isUpdateBank = isFormUpdate === 'true' && formUpdateValidation.includes(piiValidationUpdateOptions.bank)

    if (isFormUpdate && isFormUpdate === 'true') {
      if (!(isUpdateAll || isUpdateBank)) {
        return <></>
      }
    } else {
      if (!isSectionEnabled) {
        return <></>
      }
    }

    return (
      <>
        <GridItem xs={12} className="padding-bottom:5px">
          <h5>
            <strong>Banking Details</strong>
          </h5>
        </GridItem>
        <GridItem xs={12} hidden={uploadBankDocumentationEnabled !== 'true'}>
          <FormControlLabel
            control={
              <Switch
                checked={uploadBankDocumentationChecked}
                onChange={event => setUploadBankDocumentationChecked(event.target.checked)}
                classes={{
                  switchBase: buttonClases.switchBase,
                  checked: buttonClases.switchChecked,
                  thumb: buttonClases.switchIcon,
                  track: buttonClases.switchBar
                }}
                disabled={bankRetryCount < 1 || validationStatus[piiValidationTypes.bank]}
              />
            }
            classes={{
              label: buttonClases.label
            }}
            label="Upload Bank Documentation"
            labelPlacement="start"
          />
        </GridItem>
        <GridItem xs={12} hidden={!uploadBankDocumentationChecked}>
          <GridContainer justifyContent="center">
            <div style={{ width: '50%' }}>
              <DropzoneArea
                key={bankDocumentationDropzoneAreaKey}
                dropzoneClass={(isBankValidationButtonDisabled() && dropzoneAreaClasses.disabledState) || null}
                dropzoneProps={{
                  disabled: isBankValidationButtonDisabled()
                }}
                dropzoneText={'Drag and drop your bank documentation here or click.'}
                onChange={files => {
                  setBankDocumentationFiles(files)
                  if (files.length) {
                    setBankTransactionBypass(true)
                    setBankAccountOwnershipTransactionBypass(true)
                    setGiactTransactionBypass(true)
                    updateValidationStatus(piiValidationTypes.bank, true)
                    updateValidationStatus(piiValidationTypes.bankAccountOwnership, true)
                    updateValidationStatus(piiValidationTypes.giact, true)
                  } else {
                    setBankTransactionBypass(false)
                    setBankAccountOwnershipTransactionBypass(false)
                    setGiactTransactionBypass(false)
                    updateValidationStatus(piiValidationTypes.bank, false)
                    updateValidationStatus(piiValidationTypes.bankAccountOwnership, false)
                    updateValidationStatus(piiValidationTypes.giact, false)
                  }
                }}
                filesLimit={1}
                showPreviews={false}
                showPreviewsInDropzone={true}
                showFileNamesInPreview={true}
                showFileNames={true}
              />
            </div>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} className="padding-bottom:5px">
          <h6
            style={{
              color: 'red',
              hidden: validationStatus[piiValidationTypes.bank],
              whiteSpace: 'pre-line'
            }}
          >
            <strong>{validationMessage}</strong>
          </h6>
          <h6
            style={{
              color: 'orange',
              hidden: validationStatus[piiValidationTypes.bank],
              whiteSpace: 'pre-line'
            }}
          >
            <span>{warningValidationMessage}</span>
          </h6>
        </GridItem>
        <GridItem xs={6}>
          <FormControl>
            <InputLabel id="bank-country-select-label">Bank"s Country</InputLabel>
            <Select
              labelId="bank-country-select-label"
              id="bank-country-select"
              label="Bank Country"
              MenuProps={{ style: { zIndex: 5555 } }}
              inputProps={isUpdateBank ? { name: 'stepId', id: 'fieldType' } : undefined}
              disabled={validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch}
              style={{ minWidth: '340px', float: 'left' }}
              value={country}
              onChange={e => setCountry(e.target.value)}
            >
              {countryListOptions.map(value => {
                return (
                  <MenuItem value={value.name} key={value.name}>
                    {value.name}
                  </MenuItem>
                )
              })}
            </Select>
            <FormHelperText id="my-helper-text"></FormHelperText>
          </FormControl>
        </GridItem>
        <GridItem xs={6} hidden={!includeBankAccountType}>
          <FormControl>
            <InputLabel id="account-type-select-label">
              Account Type
              {mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true' && accountTypeField && accountTypeField === 'true' ? '*' : ''}
            </InputLabel>
            <Select
              labelId="account-type-select-label"
              id="account-type-select"
              label="Account Type"
              MenuProps={{ style: { zIndex: 5555 } }}
              inputProps={isUpdateBank ? { name: 'stepId', id: 'fieldType' } : undefined}
              disabled={validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch}
              style={{ minWidth: '340px', float: 'left' }}
              value={accountType}
              onChange={e => setAccountType(e.target.value)}
            >
              <MenuItem value={'Checking'} key={'Checking'}>
                {'Checking'}
              </MenuItem>
              <MenuItem value={'Savings'} key={'Savings'}>
                {'Savings'}
              </MenuItem>
              <MenuItem value={'Loan'} key={'Loan'}>
                {'Loan'}
              </MenuItem>
            </Select>
            <FormHelperText id="my-helper-text"></FormHelperText>
          </FormControl>
        </GridItem>
        {/* Account Holder Name field */
        returnBankAccountHolderName()}
        <GridItem xs={6}>
          <CustomInput
            labelText={`Account Number${
              mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true' && accountNumberField && accountNumberField === 'true'
                ? '*'
                : ''
            }`}
            labelProps={{
              style: { width: '100%', float: 'left' }
            }}
            id="accountNumber"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'text',
              value: accountNumber,
              onChange: event => {
                let acceptable = false
                setAccountNumber(event.target.value)
                if (
                  (country === 'United States' && piiValidationInputFormatMessages.bank.USaccountNumber.regex.test(event.target.value)) ||
                  !event.target.value
                ) {
                  acceptable = true
                } else if (
                  (country !== 'United States' && piiValidationInputFormatMessages.bank.nonUSaccountNumber.regex.test(event.target.value)) ||
                  !event.target.value
                ) {
                  acceptable = true
                }
                setAccountNumberInvalidFormat(!acceptable)
              },
              disabled: (() => {
                let isDisable = false
                if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                  isDisable = true
                }
                return isDisable
              })(),
              endAdornment: mandatoryFieldsConfiguration ? (
                accountNumberField === 'false' && ibanNumberField === 'false' ? (
                  status['accountNumber'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status['accountNumber'] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
                ) : accountNumberField === 'false' ? (
                  status['accountNumber'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : null
                ) : status['accountNumber'] ? (
                  <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                ) : status['accountNumber'] === false ? (
                  <ClearOutlinedIcon style={{ color: 'red' }} />
                ) : null
              ) : status['accountNumber'] ? (
                <CheckCircleOutlinedIcon style={{ color: 'green' }} />
              ) : status['accountNumber'] === false ? (
                <ClearOutlinedIcon style={{ color: 'red' }} />
              ) : null
            }}
          />
          {accountNumberInvalidFormat && accountNumber?.length > 0 ? (
            <FormHelperText id="account-value-1" error={true}>
              {`${
                country === 'United States'
                  ? piiValidationInputFormatMessages.bank.USaccountNumber.message
                  : piiValidationInputFormatMessages.bank.nonUSaccountNumber.message
              }`}
            </FormHelperText>
          ) : (
            ''
          )}
        </GridItem>
        <GridItem xs={6}>
          <CustomInput
            labelText={`IBAN Number${
              mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true' && ibanNumberField && ibanNumberField === 'true' ? '*' : ''
            }`}
            labelProps={{
              style: { width: '100%', float: 'left' }
            }}
            id="ibanNumber"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'text',
              value: ibanNumber,
              onChange: event => {
                let acceptable = false
                setIbanNumber(event.target.value)
                if (piiValidationInputFormatMessages.bank.ibanNumber.regex.test(event.target.value) || !event.target.value) {
                  acceptable = true
                }
                setIbanNumberInvalidFormat(!acceptable)
              },
              disabled: (() => {
                let isDisable = false
                if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                  isDisable = true
                }
                return isDisable
              })(),
              endAdornment: mandatoryFieldsConfiguration ? (
                accountNumberField === 'false' && ibanNumberField === 'false' ? (
                  status['ibanNumber'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status['ibanNumber'] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
                ) : ibanNumberField === 'false' ? (
                  status['ibanNumber'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : null
                ) : status['ibanNumber'] ? (
                  <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                ) : status['ibanNumber'] === false ? (
                  <ClearOutlinedIcon style={{ color: 'red' }} />
                ) : null
              ) : status['ibanNumber'] ? (
                <CheckCircleOutlinedIcon style={{ color: 'green' }} />
              ) : status['ibanNumber'] === false ? (
                <ClearOutlinedIcon style={{ color: 'red' }} />
              ) : null
            }}
          />
          {ibanNumberInvalidFormat && ibanNumber?.length > 0 ? (
            <FormHelperText id="ibanNumber-value-1" error={true}>
              {`${piiValidationInputFormatMessages.bank.ibanNumber.message}`}
            </FormHelperText>
          ) : (
            ''
          )}
        </GridItem>
        <GridItem xs={6}>
          <CustomInput
            labelText={`Routing Number${
              mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true' && routingNumberField && routingNumberField === 'true'
                ? '*'
                : ''
            }`}
            labelProps={{
              style: { width: '100%', float: 'left' }
            }}
            id="routingNumber"
            formControlProps={{
              fullWidth: true
            }}
            required
            inputProps={{
              type: 'text',
              value: routingNumber,
              onChange: event => {
                let acceptable = false
                setRoutingNumber(event.target.value)
                console.log('the country', { context: country })
                if (
                  (country === 'United States' && piiValidationInputFormatMessages.bank.USbankCode.regex.test(event.target.value)) ||
                  !event.target.value
                ) {
                  acceptable = true
                } else if (
                  (country !== 'United States' && piiValidationInputFormatMessages.bank.nonUSbankCode.regex.test(event.target.value)) ||
                  !event.target.value
                ) {
                  acceptable = true
                }
                setRoutingNumberInvalidFormat(!acceptable)
              },
              disabled: (() => {
                let isDisable = false
                if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                  isDisable = true
                }
                return isDisable
              })(),
              endAdornment: mandatoryFieldsConfiguration ? (
                routingNumberField === 'false' && swiftCodeField === 'false' ? (
                  status['routingNumber'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status['routingNumber'] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
                ) : routingNumberField === 'false' ? (
                  status['routingNumber'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : null
                ) : status['routingNumber'] ? (
                  <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                ) : status['routingNumber'] === false ? (
                  <ClearOutlinedIcon style={{ color: 'red' }} />
                ) : null
              ) : status['routingNumber'] ? (
                <CheckCircleOutlinedIcon style={{ color: 'green' }} />
              ) : status['routingNumber'] === false ? (
                <ClearOutlinedIcon style={{ color: 'red' }} />
              ) : null
            }}
          />
          {routingNumberInvalidFormat && routingNumber?.length > 0 ? (
            <FormHelperText id="routingNumber-value-1" error={true}>
              {`${
                country === 'United States'
                  ? piiValidationInputFormatMessages.bank.USbankCode.message
                  : piiValidationInputFormatMessages.bank.nonUSbankCode.message
              }`}
            </FormHelperText>
          ) : (
            ''
          )}
        </GridItem>
        <GridItem xs={6}>
          <CustomInput
            labelText={`SWIFT code${
              mandatoryFieldsConfiguration && mandatoryFieldsConfiguration === 'true' && swiftCodeField && swiftCodeField === 'true' ? '*' : ''
            }`}
            labelProps={{
              style: { width: '100%', float: 'left' }
            }}
            id="swiftCode"
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: 'text',
              value: swiftCode,
              onChange: event => {
                let acceptable = false
                setSwiftCode(event.target.value)
                if (piiValidationInputFormatMessages.bank.swiftCode.regex.test(event.target.value) || !event.target.value) {
                  acceptable = true
                }
                setSwiftCodeInvalidFormat(!acceptable)
              },
              disabled: (() => {
                let isDisable = false
                if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                  isDisable = true
                }
                return isDisable
              })(),
              endAdornment: mandatoryFieldsConfiguration ? (
                routingNumberField === 'false' && swiftCodeField === 'false' ? (
                  status['swiftCode'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status['swiftCode'] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
                ) : swiftCodeField === 'false' ? (
                  status['swiftCode'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : null
                ) : status['swiftCode'] ? (
                  <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                ) : status['swiftCode'] === false ? (
                  <ClearOutlinedIcon style={{ color: 'red' }} />
                ) : null
              ) : status['swiftCode'] ? (
                <CheckCircleOutlinedIcon style={{ color: 'green' }} />
              ) : status['swiftCode'] === false ? (
                <ClearOutlinedIcon style={{ color: 'red' }} />
              ) : null
            }}
          />
          {swiftCodeInvalidFormat && swiftCode?.length > 0 ? (
            <FormHelperText id="swift-value-2" error={true}>
              {`${piiValidationInputFormatMessages.bank.swiftCode.message}`}
            </FormHelperText>
          ) : (
            ''
          )}
        </GridItem>
        <GridItem xs={12}>
          <CustomInput
            labelText={'Bank Name*'}
            labelProps={{
              style: { width: '100%', float: 'left' }
            }}
            id="bankName"
            formControlProps={{
              fullWidth: true
            }}
            required
            inputProps={{
              type: 'text',
              value: bankName,
              onChange: event => {
                let acceptable = false
                setBankName(event.target.value)
                if (piiValidationInputFormatMessages.bank.bankName.regex.test(event.target.value)) {
                  acceptable = true
                }
                setBankNameInvalidFormat(!acceptable)
              },
              disabled: (() => {
                let isDisable = false
                if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                  isDisable = true
                }
                return isDisable
              })(),
              endAdornment: status['bankName'] ? (
                <CheckCircleOutlinedIcon style={{ color: 'green' }} />
              ) : status['bankName'] === false ? (
                <ClearOutlinedIcon style={{ color: 'red' }} />
              ) : null
            }}
          />
          {bankNameInvalidFormat && bankName?.length > 0 ? (
            <FormHelperText id="bankName-value-1" error={true}>
              {`${piiValidationInputFormatMessages.bank.bankName.message}`}
            </FormHelperText>
          ) : (
            ''
          )}
        </GridItem>
        {customBankFieldsHtml}
        <GridItem xs={12}>
          <div className={wizardClasses.right}>
            <Tooltip title={'Click to Validate '}>
              <Button
                color={
                  validationStatus[piiValidationTypes.bank] && bankTransactionBypass
                    ? 'warning'
                    : validationStatus[piiValidationTypes.bank]
                    ? 'info'
                    : isProcessingBank
                    ? 'secondary'
                    : 'danger'
                }
                onClick={() => {
                  validatePiiData(piiValidationTypes.bank)
                }}
                round
                disabled={isBankValidationButtonDisabled() || validationStatus[piiValidationTypes.bank]}
                className={classes.documentation}
              >
                {validationStatus[piiValidationTypes.bank] && bankTransactionBypass ? (
                  <div>
                    <CheckBox className={classes.icons} /> Bypassed
                  </div>
                ) : validationStatus[piiValidationTypes.bank] ? (
                  <div>
                    <CheckBox className={classes.icons} /> Validated
                  </div>
                ) : (
                  <>
                    {isProcessingBank ? (
                      <div>
                        <CircularProgress size={12} style={{ color: 'white', display: 'inline', marginRight: '7px', marginLeft: '-10px' }} />{' '}
                        Processing...
                      </div>
                    ) : (
                      <div>
                        <Cancel className={classes.icons} /> Click To Validate
                      </div>
                    )}
                  </>
                )}
              </Button>
            </Tooltip>
          </div>
        </GridItem>
      </>
    )
  }
  function returnBankAccountHolderName() {
    if (requireBankAccountHolder !== 'true') {
      return <></>
    }

    // Individual
    if (supplierType === 'Individual' && bankAccountOwnershipValidationEnabled === 'true') {
      if (
        (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.GIACT && giactConfigurations.enabled) ||
        (bankAccountOwnershipValidationProvider === BankAccountOwnershipProvider.DATA_ASSURE && bankAccountOwnershipConfigurations?.enabled)
      ) {
        return (
          <>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={6} hidden={uploadW8W9Form}>
              <CustomInput
                labelText={`Account Holder First Name*`}
                labelProps={{
                  style: { width: '100%', float: 'left' }
                }}
                id="accountHolderFirstName"
                formControlProps={{
                  fullWidth: true
                }}
                required
                inputProps={{
                  type: 'text',
                  value: accountHolderFirstName,
                  disabled: (() => {
                    let val = false
                    if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                      val = true
                    }
                    return val
                  })(),
                  onChange: event => {
                    setAccountHolderFirstName(event.target.value)
                  },
                  endAdornment: status['accountHolderFirstName'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status['accountHolderFirstName'] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
                }}
              />
              {accountHolderFirstName?.length === 0 ? (
                <FormHelperText id="account-holder-first-name" error={true}>
                  {`"Account Holder First Name" is required`}
                </FormHelperText>
              ) : (
                ''
              )}
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={6} xl={6} hidden={uploadW8W9Form}>
              <CustomInput
                labelText={`Account Holder Last Name*`}
                labelProps={{
                  style: { width: '100%', float: 'left' }
                }}
                id="accountHolderLastName"
                formControlProps={{
                  fullWidth: true
                }}
                required
                inputProps={{
                  type: 'text',
                  value: accountHolderLastName,
                  disabled: (() => {
                    let val = false
                    if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                      val = true
                    }
                    return val
                  })(),
                  onChange: event => {
                    setAccountHolderLastName(event.target.value)
                  },
                  endAdornment: status['accountHolderLastName'] ? (
                    <CheckCircleOutlinedIcon style={{ color: 'green' }} />
                  ) : status['accountHolderLastName'] === false ? (
                    <ClearOutlinedIcon style={{ color: 'red' }} />
                  ) : null
                }}
              />
              {accountHolderLastName?.length === 0 ? (
                <FormHelperText id="account-holder-last-name" error={true}>
                  {`"Account Holder Last Name" is required`}
                </FormHelperText>
              ) : (
                ''
              )}
            </GridItem>
          </>
        )
      }
    }

    // Default or Organization
    return (
      <GridItem xs={12}>
        <CustomInput
          labelText={`Account Holder Legal Name*`}
          labelProps={{
            style: { width: '100%', float: 'left' }
          }}
          id="accountHolderLegalName"
          formControlProps={{
            fullWidth: true
          }}
          required
          inputProps={{
            type: 'text',
            value: accountHolderLegalName,
            disabled: (() => {
              let val = false
              if (validationStatus[piiValidationTypes.bank] || isProcessingBank || isSupplierNameMismatch) {
                val = true
              }
              return val
            })(),
            onChange: event => {
              setAccountHolderLegalName(event.target.value)
            },
            endAdornment: status['accountHolderLegalName'] ? (
              <CheckCircleOutlinedIcon style={{ color: 'green' }} />
            ) : status['accountHolderLegalName'] === false ? (
              <ClearOutlinedIcon style={{ color: 'red' }} />
            ) : null
          }}
        />
        {accountHolderLegalName?.length === 0 ? (
          <FormHelperText id="account-holder-legal-name" error={true}>
            {`"Account Holder Legal Name" is required`}
          </FormHelperText>
        ) : (
          ''
        )}
      </GridItem>
    )
  }

  return (
    <Fade in={true} timeout={250}>
      {encryptedAndSent ? thanksPage() : returnForm()}
    </Fade>
  )
}
